
import { defineComponent } from 'vue'
import AdminLayout from '@/screens/layouts/AdminLayout.vue'
import api from '@/config/api'

type FormData = {
    corretor_nome: string;
    corretor_cpf: string;
    corretor_rg: string;
    corretor_email: string;
    corretor_phone?: string;
    corretor_phone_whatsapp?: string;
    avatar?: string;
    creci: string;
}

type DataTypes = {
    dados: FormData;
    leadMessage: string;
    errors: Array<string>;
    loading: boolean;
    id: number;
}
 

type ApiResponseContactJson = {
    message: string;
    errors?: string[];
}

export default defineComponent({
    data(): DataTypes {
        return {
            dados: {
                corretor_nome: null,
                corretor_cpf: null,
                corretor_rg: null,
                corretor_email: null,
                corretor_phone: null,
                corretor_phone_whatsapp: null,
                avatar: null,
                creci: null
            },
            leadMessage: "",
            errors: [],
            loading: false,
            id: 0
        }
    },
    components: {
        AdminLayout
    },
    created() {
        let idValue = this.$route.params.id;
        let idValueInt: number = +idValue
        console.log(this.dados);
        this.id = idValueInt;
        if (this.id) {
            this.getAgentData(this.id)
        }
    },
    methods: {
        sendAgentForm(): void {
            this.loading = true;
            this.showErrors({} as ApiResponseContactJson);
            if (this.id) {                
                const params = {id: this.id, ...this.dados}
                api.
                put<ApiResponseContactJson>('auth/putagents', params)
                .then(res => {
                    this.dados = {} as FormData;
                    this.showErrors(res.data);
                })
                .catch((error) => {
                    const data: ApiResponseContactJson = error.response.data || {
                        message: "Houve um erro ao processar requisição"
                    };
                    this.showErrors(data);
                })
                .finally(() => {
                    this.loading = false
                    alert("Corretor alterado com sucesso")
                    this.$router.push('/admin/gerenciar-corretores')
                });
            } else {
                api
                .post<ApiResponseContactJson>('auth/addagents', this.dados)
                .then(res => {
                    this.dados = {} as FormData;
                    this.showErrors(res.data);
                })
                .catch((error) => {
                    const data: ApiResponseContactJson = error.response.data || {
                        message: "Houve um erro ao processar requisição"
                    };
                    this.showErrors(data);
                })
                .finally(() => {
                    this.loading = false
                    alert("Corretor criado com sucesso")
                    this.$router.push('/admin/gerenciar-corretores')
                })                
            }
        },
        showErrors(data: ApiResponseContactJson): void {
            this.leadMessage = data.message;
            this.errors = data.errors || [];
        },
        getAgentData(id: number) {
            this.loading = true;
            api.get(`agent/getAgents/${id}`)            
            .then(res => {
                const data = res.data.data
                this.dados = {} as FormData
                if (data)
                console.log(data[0])
                this.errors = res.data.errors
                return this.dados = data[0];
            })
            .catch(() => {
                this.leadMessage = 'Houve um erro ao tentar carregar o corretor especifico'
            })
            .finally(() => {
                console.log(this.dados)
                this.loading = false
            })
        }
        // getImage(e: InputEvent) {
        //     const file = e.data || e.dataTransfer;
        //     if (!file.length)
        //         return;
        //     this.createImage(file[0]);
        // }
    }
})
