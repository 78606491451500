<template>
  <a id="go-to-top-button" class="go-to-top d-flex align-items-center justify-content-center position-fixed rounded-circle"
     href="#">
    <fa :icon="['fas', 'chevron-up']" class="icon"/>
  </a>
</template>

<script>
export default {
  methods: {
    onScroll: () => {
        var elementsWpp = document.querySelectorAll('.floating-wpp-button');
      if (window.scrollY > 100) {
        elementsWpp.forEach(element => {
            element.classList.add('adjust-wpp-btn');
        });
        return document.getElementById('go-to-top-button').classList.add('visible')
      }
        elementsWpp.forEach(element => {
            element.classList.remove('adjust-wpp-btn');
        });
      return document.getElementById('go-to-top-button').classList.remove('visible')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
.go-to-top {
  transition: 300ms;
  visibility: hidden;
  opacity: 0;
  width: 50px;
  height: 50px;
  right: 28px;
  bottom: 40px;
  background-color: $primary;
  padding: 10px;
  z-index: 1000;

  &.visible {
    opacity: 1;
  }

  .icon {
    color: white;
    width: 100%;
    height: 100%;
  }
}
</style>
