import axios, {AxiosRequestConfig } from 'axios';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:8000';

// console.log(process.env.VUE_API_URL)
const axiosConfig: AxiosRequestConfig = {
    baseURL: API_URL + "/api/",
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
    }
}

export function getCookie(cname: string) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default axios.create(axiosConfig);