
    import { defineComponent, PropType } from "vue";
    import { IPagination } from '@/config/apiTypes';

    export default defineComponent({
        props: {
            pagination: {
                type: Object as PropType<IPagination>,
                default: () => {
                    return {
                        total: 0,
                        count: 0,
                        per_page: 0,
                        current_page: 1,
                        total_pages: 0
                    }
                }
            }
        },
        methods: {
            changePage(page: number) {
                this.$emit('changepage', page);
            },
            getPagination(currentPage: number, totalPages: number): number[] {
                const array = [];
                for (let index = currentPage - 4; index <= currentPage + 3 && index <= totalPages; index++) {
                    array.push(index);
                }
                const arrayNaturalized = array.filter(value => value > 0);
                if(arrayNaturalized.length > 4 && (currentPage > 1 || currentPage < totalPages) ) {
                    const index = arrayNaturalized.indexOf(currentPage) - 2 < 0 ? 0 : arrayNaturalized.indexOf(currentPage) - 2;
                    return arrayNaturalized.splice(index, 4);
                }

                return arrayNaturalized;
            }
        }
    })
