
    import { defineComponent } from 'vue';
    import MainLayout from './layouts/MainLayout.vue';
    import api from '@/config/api';

    type DataTypes = {
        leadMessage: string;
        errors: string[];
        loading: boolean;
    }

    type ApiResponseContactJson = {
        message: string;
        errors?: string[];
    }

    export default defineComponent({
        data(): DataTypes {
            return {
                leadMessage: "",
                errors: [],
                loading: false
            };
        },
        components: {
            MainLayout
        },
        methods: {
            sendForm(): void {
                this.showErrors({} as ApiResponseContactJson);
                const form = document.querySelector('#workUsForm') as HTMLFormElement;

                if(!form) {
                    return this.showErrors({ message: "Houve um erro ao enviar formulário" });
                }

                this.loading = true;
                const formData = new FormData(form);
                
                api
                .post<ApiResponseContactJson>(
                    'work-us', 
                    formData, 
                    { 
                        headers: {
                            'Content-Type': "multipart/form-data"
                        } 
                    }
                )
                .then(res => {
                    this.showErrors(res.data);
                    form.reset();
                })
                .catch((error) => {
                    const data: ApiResponseContactJson = error.response.data || {
                        message: "Houve um erro ao processar requisição"
                    };
                    this.showErrors(data);
                })
                .finally(() => this.loading = false);

            },
            showErrors(data: ApiResponseContactJson): void {
                this.leadMessage = data.message;
                this.errors = data.errors || [];
            }
        }
    })
