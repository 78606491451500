
    import { defineComponent } from 'vue';
    import NotFound from '@/screens/404.vue';
    import api from '@/config/api';

    type data = {
        CodigoImovel: string;
        html: HTMLElement
    }
    
    type DataTypes = {
        data: data;
        leadMessage: string;
        errors: string[];
        loading: boolean;
        notFound: boolean;
    }

    export default defineComponent({
        data(): DataTypes {
            return {
                data: {
                    CodigoImovel: null,
                    html: null
                },
                leadMessage: "",
                errors: [],
                loading: false,
                notFound: false
            }
        },
        components: {
            NotFound        
        },
        methods: {
            getPrintPage(){
                this.loading = true;

                api.get(`/print?code=${this.data.CodigoImovel}`)
                .then(res => {
                    if(res.data.html=="")
                        this.notFound = true;
                    else
                        this.data.html = res.data.html;
                })
                .catch(() => {
                    this.notFound = true;
                })
                .finally(() => {
                    this.loading = false;
                }); 
            }
        },
        mounted() {
        
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('code');

            this.data.CodigoImovel = code;

            this.getPrintPage();

            setTimeout(print, 3000); //timeout pra ter certeza que a requisição que pega o html já foi feita

            function print() {
                var img = document.getElementById('imgImovel') as HTMLImageElement;
                            
                function loaded() {
                    var divs = document.getElementsByClassName('alignPrintItems') as any; 

                    //Arrumando estilização bugada
                    for (let item of divs) {
                        item.style.fontSize = "70%";
                        item.style.whiteSpace = "nowrap";
                        item.style.color = "black";
                    }
                    //Printando imóvel
                    window.print();
                }

                if (img && img.complete && img.naturalWidth > 0) {
                    console.log('completed')
                    loaded()
                } else if(img){
                    img.addEventListener('load', loaded)
                }

            }

        }
    })
