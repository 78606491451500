
    import { defineComponent } from 'vue';
    import api from '@/config/api';
    import allowedSubdomains from '@/config/allowedSubdomains';

    type VariablesData = {
        creci: string,
        endereco: string,
        fone: string,
        email: string,
        whatsapp: string
    }

    type DataTypes = {
        corretor: VariablesData;
        leadMessage: string;
        errors: string[];
        loadingMessage: string;
        // loading: boolean;
    }

    export default defineComponent({
        data(): DataTypes {
            return {
                corretor:{
                    creci: '4576J',
                    endereco: 'Rua Monsenhor Celso, 231. Centro, Curitiba - PR',
                    fone: '41 3014.1101',
                    email: 'contato.galvao@vendasg.com.br',
                    whatsapp: '41 3014.1101'
                },
                leadMessage: "",
                errors: [],
                loadingMessage: "Procurando por dados do corretor..."
            }
        },
        mounted(){
            this.getRealtor();
        },
        methods: {
            getRealtor(){

                api
                .get('/agent/getAgents')
                .then(res => {
                    
                    const subdomain = window.location.host.split('.')[0];

                    if(allowedSubdomains.includes(subdomain) && !subdomain.includes('galvaovendas') && !subdomain.includes('localhost')){
                        const corretor = res.data.data;
                        
                        corretor.forEach((value: any)=> {

                            if(value.corretor_nome.toUpperCase().includes(subdomain.toUpperCase())){
                                this.corretor.creci = value.creci;
                                this.corretor.fone = value.corretor_phone;
                                this.corretor.email = value.corretor_email;
                                this.corretor.whatsapp = value.corretor_phone_whatsapp;
                            }
                        })

                    }

                })
                .catch(err => {
                    console.log(err);
                })
            }
        }
    })
    
