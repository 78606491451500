<template>
	<transition appear>
		<div class="cookies-warning container" v-if="!accepted">
			<div class="d-flex">
				<div class="">
					<div class="row">
						<div class="col-12">
							<p class="text-white">
								Este site utiliza cookies e tecnologias semelhantes para melhorar a sua experiência de navegação.
								Você pode configurar o seu navegador para rejeitar estes cookies. Nesse caso, a navegação e os serviços
								deste site podem ser limitados.
								Se você não modificar as opções e continuar navegando, estará ciente acerca do uso de cookies neste
								site, aceitando-a.
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<button class="btn btn-danger" @click="accept">
								Aceitar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	// This component is used to display a warning message to the user about the use of cookies.
	// It should be displayed in all pages.
	// When the user clicks on the "Accept" button, the cookie warning is hidden, and it is not displayed again.
	// The state should be stored in the local storage.
	
	export default {
		name: 'CookiesWarning',
		data() {
			return {
				accepted: false,
			}
		},
		methods: {
			accept() {
				localStorage.setItem('cookies-accepted', "1");
				this.accepted = true;
			},
		},
		created() {
			this.accepted = (localStorage.getItem('cookies-accepted') ?? "0") === "1";
		},
	}
</script>

<style scoped>
.cookies-warning {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 1rem;
	text-align: center;
	z-index: 99999999999;
	background-color: rgba(0, 0, 0, 0.65);
	transition: transform .6s ease-in-out;
}

.v-enter-active {
	transition-delay: 5s;
}

.v-leave-active {
	transition-delay: 0s;
}

.v-enter-to, .v-leave-from, .v-leave-active {
	transform: translateY(0);
}

.v-enter-from, .v-leave-to, .v-enter-active  {
	transform: translateY(100%);
}
</style>