
    import { defineComponent } from 'vue';
    import MainLayout from './layouts/MainLayout.vue';
    import api from '@/config/api';

    type FormData = {
        nome: string;
        email: string;
        mensagem?: string;
        assunto?: string;
        telefone?: string;
    }

    type DataTypes = {
        dados: FormData;
        leadMessage: string;
        errors: string[];
        loading: boolean;
    }

    type ApiResponseContactJson = {
        message: string;
        errors?: string[];
    }

    export default defineComponent({
        data(): DataTypes {
            return {
                dados: {
                    nome: null,
                    email: null,
                    mensagem: null,
                    assunto: null,
                    telefone: null,
                },
                leadMessage: "",
                errors: [],
                loading: false
            };
        },
        components: {
            MainLayout
        },
        methods: {
            sendContactForm(): void {
                this.loading = true;
                this.showErrors({} as ApiResponseContactJson);

                api
                .post<ApiResponseContactJson>('contact', this.dados)
                .then(res => {
                    this.dados = {} as FormData;
                    this.showErrors(res.data);
                })
                .catch((error) => {
                    const data: ApiResponseContactJson = error.response.data || {
                        message: "Houve um erro ao processar requisição"
                    };
                    this.showErrors(data);
                })
                .finally(() => this.loading = false);
            },
            showErrors(data: ApiResponseContactJson): void {
                this.leadMessage = data.message;
                this.errors = data.errors || [];
            }
        }
    })
