
import {defineComponent} from "vue";
import AdminLayout from "@/screens/layouts/AdminLayout.vue";
import api from "@/config/api";

export default defineComponent({
  name: 'Tags',
  components: {
    AdminLayout
  },
  data() {
    return {
      loading: false,
      loadingDel: false,
      openCreateModal: false,
      showConfirmDeletionModal: false,
      selectedTagId: null,
      fromEditTag: false,
      modal: {
        selectedId: '',
        title: '',
        page: null,
        description: '',
        keywords: '',
        errors: []
      },
      tags: {
        data: [],
        pages: [],
        loading: false,
        errors: []
      }
    }
  },
  mounted() {
    this.getTags();
  },
  methods: {
    handleSubmit() {
      if (this.fromEditTag) {
        return this.updateForm();
      }

      return this.saveForm();
    },
    saveForm() {
      this.loading = true;

      const modalData = {
        title: this.modal.title,
        description: this.modal.description,
        keywords: this.modal.keywords,
        page: this.modal.page
      }

      api.post('/tags', {...modalData})
          .then(response => {
            console.log(response.data);
            this.resetForm();
          })
          .catch(err => {
            this.modal.errors = err.response.data.errors
            console.log(this.modal.errors);
          })
          .finally(() => {
            this.loading = false;
            this.getTags();
          })
    },
    updateForm() {
      this.loading = true;
      const modalData = {
        title: this.modal.title,
        description: this.modal.description,
        keywords: this.modal.keywords,
        page: this.modal.page
      }

      api.post('/tags/' + this.modal.selectedId, {...modalData})
          .then(response => {
            console.log(response.data);
            this.resetForm();
          })
          .catch(err => {
            this.modal.errors = err.response.data.errors
          })
          .finally(() => {
            this.loading = false;
            this.getTags();
          })
    },
    getTags() {
      this.tags.loading = true;
      api.get('tags/')
          .then(response => {
            this.tags.data = response.data.tags
            this.tags.pages = response.data.page
          })
          .catch(err => {
            this.tags.errors = err.response.data.errors;
          })
          .finally(() => this.tags.loading = false)
    },
    editTag(id: string) {
      const item = this.tags.data.filter(item => item.id == id)[0];

      if (item) {
        this.modal.selectedId = item.id;
        this.modal.title = item.title;
        this.modal.description = item.description;
        this.modal.keywords = item.keywords;
        this.modal.page = item.page;
        this.openCreateModal = true;
      }
    },
    resetForm() {
      this.modal.selectedId = '';
      this.modal.title = '';
      this.modal.description = '';
      this.modal.keywords = '';
      this.modal.page = null;
      this.modal.errors = [];
      this.fromEditTag = false;
      this.openCreateModal = false;
    },
    cancelDelete() {
      this.selectedTagId = null;
      this.showConfirmDeletionModal = false;
    },
    deleteTag() {
      console.log(this.selectedTagId);
      this.loadingDel = true;
      api.delete('tags/' + this.selectedTagId)
          .then(response => console.log(response))
          .catch(err => console.log(err))
          .finally(() => {
            this.selectedTagId = null;
            this.showConfirmDeletionModal = false;
            this.loadingDel = false;
            this.getTags();
          })
    },
    confirmDelete(id: string) {
      this.showConfirmDeletionModal = true;
      this.selectedTagId = id;
    }
  }
})
