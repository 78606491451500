import { createApp } from 'vue';
import App from './App.vue';
import { mainRouter } from './routes';

//importando font-awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// font-awesome solid svg
import { faThLarge, faMapMarker, faAngleLeft, faAngleRight, faPrint, faSpinner, faPhoneAlt, faBed, faShare, faArrowsAlt, faHome, faEnvelope, faMapMarkerAlt, faSearch, faChevronRight, faChevronLeft, faChevronUp, faExpand, faCar, faBath, faCopy, faCheck, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
library.add(faThLarge, faMapMarker, faAngleLeft, faAngleRight, faPrint, faPhoneAlt, faSpinner, faBed, faArrowsAlt, faShare, faHome, faCopy, faEnvelope, faMapMarkerAlt, faSearch, faChevronRight, faChevronLeft, faChevronUp, faExpand, faCar, faBath, faCheck, faPencilAlt, faTrash );

// font-awesome brands svg
import { faSlackHash ,faInstagramSquare, faWhatsapp, faFacebookSquare, faLinkedin, faTwitter, faBloggerB } from '@fortawesome/free-brands-svg-icons';
// @ts-ignore
library.add(faSlackHash, faInstagramSquare, faWhatsapp, faFacebookSquare, faLinkedin, faTwitter, faBloggerB);

import  VueGoogleMaps from '@fawmi/vue-google-maps';

createApp(App)
.use(mainRouter)
.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    }
})
.component('fa', FontAwesomeIcon)
.mount('#app')
