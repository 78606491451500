
import api from '@/config/api';
import ShareModal from '@/components/ShareModal.vue';
import {IFieldProperty, IProperties, IPagination} from '@/config/apiTypes';
import {routeFilters, routeFiltersType} from '@/routes';
import {defineComponent} from 'vue';
import {formatCashNumber, getUrl} from '@/utils/functions';
import FloatingButtons from '@/components/FloatingButtons.vue';
import allowedSubdomains from '@/config/allowedSubdomains';

const slider = document.querySelector('.slider-home');

interface IFilters<T> {
  data: T[];
  loading: boolean;
}

interface DataTypes {
  url_api: '',
  properties: {
    data: any,
    loading: boolean
  },
  filters: routeFiltersType, 
  tipos: IFilters<{ Tipo: string }>;
  bairros: IFilters<{ Bairro: string }>;
  filterInput: {
    dormitorios: string;
    tipo: string;
    bairro: string;
  };
  carrossel: {
    data: IProperties[];
    error: string | null;
    loading: boolean;
    pagination: IPagination;
  },
  imoveis: {
    data: IProperties[];
    error: string | null;
    loading: boolean;
    pagination: IPagination;
  };
  page: number;
  shareModal: {
    visible: boolean;
    link: string;
  };
  corretor: {
    creci: string,
    endereco: string,
    fone: string,
    email: string,
    whatsapp: string
  };
  tableBairros: Array<string>;
  pointerIsDown: boolean;
  startX: number;
  scrollLeft: number;
}

export default defineComponent({
  setup() {
    return {formatCashNumber, getUrl};
  },
  data(): DataTypes {
    return {
      url_api: process.env.VUE_APP_API_URL,
      properties: {
        data: null,
        loading: false
      },
      filters: routeFilters,
      tipos: {
        data: [],
        loading: false
      },
      bairros: {
        data: [],
        loading: false
      },
      filterInput: {
        dormitorios: "",
        tipo: "",
        bairro: ""
      },
      carrossel: {
        data: [],
        error: null,
        loading: false,
        pagination: null
      },
      imoveis: {
        data: [],
        error: null,
        loading: false,
        pagination: null
      },
      shareModal: {
        visible: false,
        link: ""
      },
      corretor: {
        creci: '4576J',
        endereco: 'Rua Monsenhor Celso, 231. Centro, Curitiba - PR',
        fone: '41 3014.1101',
        email: 'contato.galvao@vendasg.com.br',
        whatsapp: '41 3014.1101'
      },
      page: 1,
      tableBairros: [],
      pointerIsDown: false,
      startX: 0,
      scrollLeft: 0
    };
  },
  beforeMount() {
    this.getTableBairros();
    this.page = Number(this.$route.query.page) || 1;
    this.getHighlightBanners();
    this.getHighlightProperties();
  },
  mounted() {
    this.getRealtor();
    this.getEnterprises();
  },
  components: {
    ShareModal,
    FloatingButtons
  },
  computed: {
    api_url() {
      return new URL(api.defaults.baseURL).origin
    },
    client_origin() {
      return window.location.origin;
    }
  },
  methods: {
    getRealtor() {

      api
          .get('/agent/getAgents')
          .then(res => {

            const subdomain = window.location.host.split('.')[0];

            if (allowedSubdomains.includes(subdomain) && !subdomain.includes('galvaovendas') && !subdomain.includes('localhost')) {
              const corretor = res.data.data;

              corretor.forEach((value: any) => {

                if (value.corretor_nome.toUpperCase().includes(subdomain.toUpperCase())) {
                  this.corretor.creci = value.creci;
                  this.corretor.fone = value.corretor_phone;
                  this.corretor.email = value.corretor_email;
                  this.corretor.whatsapp = value.corretor_phone_whatsapp;
                }
              })

            }

          })
          .catch(err => {
            console.log(err);
          })
    },
    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getHighlightBanners() {
      api
          .get('highlight/banners')
          .then(res => {
            this.carrossel.data = res.data.data;
          })
          .catch(err => {
            console.log(err);
          })
    },
    getHighlightProperties() {
      this.imoveis.loading = true;
      api.get('/highlight/properties')
          .then(res => {
            const data = res.data.data;
            if (data) {
              this.imoveis.error = "";
              this.imoveis.pagination = res.data.pagination;
              return this.imoveis.data = data;
            }
            this.imoveis.error = "Nenhum imóvel encontrado.";
          })
          .catch(() => {
            this.imoveis.error = "Não foi possível carregar os imóveis.";
          })
          .finally(() => {
            this.imoveis.loading = false;
          })
    },
    getPropertyField(fields: IFieldProperty[], code: number, optional: string = ""): string | null {
      const field = fields.find(field => field.pivot.CodigoCampo == code);
      if (field) {
        return `${this.capitalize(field.Campo)}: ${field.pivot.Valor} ${optional}`;
      }
      return null;
    },
    getPropertyImageLink(codigo: number) {
      const cdnBaseUrl = 'https://cdn.redeimoveis.com.br/images/imoveis';
      try {
        const numberSection = Math.trunc(codigo / 1000);
        return `${cdnBaseUrl}/${numberSection}/${codigo}/${codigo}_1`;
      } catch (error) {
        return (`${cdnBaseUrl}/FOTOS_EM_BREVE`);
      }
    },
    capitalize(text: string): string {
      try {
        const textLowed = text.toLowerCase();
        return textLowed.split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      } catch (error) {
        return text;
      }
    },
    tranformDateString(text: string): string {
      const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
      const date = new Date(text);
      return `${months[date.getMonth()]}/${date.getFullYear()}`;
    },
    getTypes() {
      if (this.tipos.data.length == 0) {
        this.tipos.loading = true;
        api.get('/types')
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.tipos.data = data;
              }
            })
            .finally(() => {
              this.tipos.loading = false;
            })
      }
    },
    getDistricts() {
      if (this.bairros.data.length == 0) {
        this.bairros.loading = true;
        api.get('/districtsSite?todos=Sim')
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.bairros.data = data;
              }
            })
            .finally(() => {
              this.bairros.loading = false;
            })
      }
    },
    openShareModal(link: string) {
      this.shareModal = {
        link,
        visible: true
      }
    },
    getTableBairros() {
      api
          .get('districts')
          .then(res => {
            this.tableBairros = res.data.tableBairros;
          })
          .catch(err => {
            console.log(err)
          })
    },
    getBairroPai(bairro: string) {
      //Obtendo bairro pai, caso houver
      this.tableBairros.forEach((value: any) => {
        if (value.bairro == bairro && value.id_bairro_pai) {
          this.tableBairros.forEach((val: any) => {
            if (val.id == value.id_bairro_pai)
              bairro = val.bairro; //Substitui pelo bairro pai
          });
        }
      });

      return bairro;
    },
    getEnterprises() {
      this.properties.loading = true;
      api.get('busca-exclusiva/')
          .then(response => {
            this.properties.data = response.data.data
            console.log('testando', this.properties.data)
          }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.properties.loading = false;
      })
    },
    handleDown(event: any) {
      this.pointerIsDown = true;
      slider.classList.add('active');
      this.startX = event.pageX + slider.scrollLeft;
    },
    handleLeave() {
      this.pointerIsDown = false;
      setTimeout(() => {
        slider.classList.remove('active');
      }, 1000)
    },
    handleUp() {
      this.pointerIsDown = false;
      setTimeout(() => {
        slider.classList.remove('active');
      }, 1000)
    },
    handleMove(event: any) {
      if (!this.pointerIsDown) return;

      event.preventDefault();
    },
    handleClick(event: any) {
      if (document.querySelector('.slider-home').classList.contains('active')) {
        event.preventDefault();
      }
    }
  }
});
