
    import { defineComponent } from 'vue';
    import MainLayout from '@/screens/layouts/MainLayout.vue';

    export default defineComponent({
        components: {
            MainLayout
        },
        methods: {
            goBack() {
                try {
                    this.$router.back()
                } catch (error) {
                    alert("Não foi possível voltar para a página anterior.")
                }
            }
        }
    });
