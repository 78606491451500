
    import FloatingButtons from '@/components/FloatingButtons.vue';
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import GoToTopButton from '@/components/GoToTopButton.vue';

    export default {
        components: {
            'app-header': Header,
            'app-footer': Footer,
            GoToTopButton,
            'floating-button': FloatingButtons
        }
    }
