
import { defineComponent } from 'vue';
import AdminLayout from '@/screens/layouts/AdminLayout.vue';
import api from '@/config/api';

interface agentData {
  id: number;
  corretor_nome: string;
  corretor_cpf: string;
  corretorn_rg: string;
  corretor_email: string;
  corretor_phone: string;
  corretor_phone_whatsapp: string;
  corretor_avatar: string;
  creci: string;
}

type ApiResponseContactJson = {
    message: string;
    errors?: string[];
}

declare interface dataType {
    corretores: {
        data: agentData[];
        error: string | null;
        loading: boolean;
    };
    confirmation: string | null;
    leadMessage: string;
    errors: Array<string>;
    loading: boolean;
}

export default defineComponent({
    name: 'Gerenciar Corretores',
    beforeMount() {
        this.getAgents()
    },
    components: {
        AdminLayout
    },
    data(): dataType {
        return {
            corretores: {
                data: [],
                error: null,
                loading: false,
            },
            confirmation: null,
            leadMessage: "",
            errors: [],
            loading: false,
        }
    },
    methods: {
        showErrors(data: ApiResponseContactJson): void {
            this.leadMessage = data.message;
            this.errors = data.errors || [];
        },
        getAgents()
        {
            this.corretores.loading = true;
            api.
            get('agent/getAgents')
            .then(res => {
                const data = res.data.data;
                if (data) {
                this.corretores.error = "";
                return this.corretores.data = data;
                }
                this.corretores.error = res.data.message
            })
            .catch(() => {
                this.corretores.error = 'Houve um erro ao tentar carregar os dados dos corretores'
            })
            .finally(() => {
                this.corretores.loading = false;
                console.log(this.corretores.data)
            })
        },
        setConfirmation(nome: string) {
            let body = document.getElementsByTagName("body")[0];
            body.style.background = "rgba(0, 0, 0, 0.7)";
            window.scroll(20, 0);
            this.confirmation = nome;
        },
        closeConfirmation() {
            let body = document.getElementsByTagName("body")[0];
            body.style.background = "none";
            this.confirmation = null;
        },
        deleteAgent(id: number) {
            this.loading = true;
            let body = document.getElementsByTagName("body")[0];
            body.style.background = "none";
            let delMsg = document.getElementById('delMsg');
            let delBtn = document.getElementById('delAgentBtn');
            let closeBtn = document.getElementById('closeAgentBtn');
            const params = {id: id};
            delBtn.style.visibility = 'hidden';
            closeBtn.style.visibility = 'hidden';
            console.log(params);
            api.delete(`auth/delagents/${id}`, { params })
            .then(res => {
                const data = res.data.data;
                console.log(data);    
                if (data) {
                    delMsg.textContent = data;
                }
            })
            .catch(res => {
                delMsg.textContent = res.data.message;
            })
            .finally(() =>{
                this.loading = false;
                this.getAgents();
                this.confirmation = null;
            })            
        }
    }
})
