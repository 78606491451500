<template>
    <main-layout>
    <div class="container-body">
        <div class="about-image"></div>
        <div class="about-image-text">
            <h1>
                CONTE COM A GENTE PARA FAZER ÓTIMOS NEGÓCIOS.
            </h1>
        </div>
    </div>


    <section class="container mb-5">
        <div class="about mt-5">

            <div class="text-about">
                <p>Todos vivem hoje em um processo constante de busca por conexão, muitas vezes sem saber exatamente o porquê.</p>
                <p>Nós, da <strong>Galvão Vendas</strong>, acreditamos que a nossa missão como profissionais do mercado imobiliário é ficar atentos a esta busca de cada indivíduo, às conexões que surgem no cotidiano, sejam relações afetivas como comerciais.</p>
                <p>Acreditamos que nada acontece por acaso.</p>
                <p>Quando há a oportunidade única de conectar-se com alguém, neste emaranhado de informações que há no mundo, inclusive no mundo virtual, a vida exige que aproveitemos da forma mais produtiva possível.</p>
                <p>Vender imóveis deve trazer a essência destas relações. Vender imóveis é criar conexões, é realizar sonhos, é dar importância às relações humanas.</p>
                <p>Por isso, temos como norte a seguinte promessa de marca: Galvão Vendas, conectando pessoas, realizando sonhos.</p>
            </div>

            <div class="services">
                <h2 class="mt-5">NOSSOS SERVIÇOS</h2>
                <div class="row mt-4 services-container">
                    <div class="col item-services avaliacao">
                        <i class="icons icon-chart mb-3"></i>
                        <h3>AVALIAÇÃO DE IMÓVEIS</h3>
                        <p>Equipe altamente capacitada, trabalhando com uma metodologia de avaliação completa para lhe auxiliar a precificar o seu imóvel.</p>
                    </div>
                    <div class="col item-services acessoria">
                        <i class="icons icon-chart mb-3"></i>
                        <h3>ASSESSORIA JURÍDICA</h3>
                        <p>Departamento jurídico experiente no mercado imobiliário para assessoria e prestação de consultoria em eventuais demandas que nossos clientes possam ter.</p>
                    </div>
                    <div class="col item-services consultoria">
                        <i class="icons icon-chart mb-3"></i>
                        <h3>CONSULTORIA PATRIMONIAL</h3>
                        <p>Oferecemos o serviço de análise da carteira imobiliária de nossos clientes, buscando apresentar novas oportunidades de investimentos e rentabilidade.</p>
                    </div>
                </div>
            </div>
            
            <div class="equipe mt-5">
                <h2>NOSSA EQUIPE</h2>
                <div class="row mt-5">
                    
                    <div class="col container-employee">
                        <img src="/assets/foto_equipe/emiliano_galvao.jpeg" class="img-responsive" alt="FOTO EMILIANO GALVÃO">
                        <div class="employee-name">EMILIANO GALVÃO</div>
                        <div>
                            <fa :icon="['fas', 'phone-alt']" /> Fone: <a href="tel:413014.1101">41 3014.1101</a>
                        </div>
                        <div>
                            <fa :icon="['fas', 'phone-alt']" />
                            <a href="tel:4199185.6701"> 41 99185.6701</a>
                        </div>
                        <div>
                            <fa :icon="['fas', 'envelope']" /> Email:
                            <a href="mailto:emiliano@galvaovendas.com.br" title="emiliano@galvaovendas.com.br">emiliano@galvaovendas.com.br</a>
                        </div>
                        <div>
                            <fa :icon="['fas', 'home']" /> CRECI: F37386
                        </div>
                    </div>
                    <div class="col container-employee">
                        <img src="/assets/foto_equipe/gerson_carlos_silva.jpg" class="img-responsive" alt="FOTO GERSON CARLOS DA SILVA">
                        <span class="employee-name">GERSON CARLOS DA SILVA</span>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" /> Fone: <a href="tel:4199187.0506">41 99187.0506</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" />
                            <a href="tel:4199187.0506"> 41 99187.0506</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'envelope']" /> Email:
                            <a href="mailto:gerson@galvaovendas.com.br" title="gerson@galvaovendas.com.br">gerson@galvaovendas.com.br</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'home']" /> CRECI: F7492
                        </div>
                    </div>
                    <div class="col container-employee">
                      <img src="/assets/foto_equipe/fernando_galvao_puhl.jpg" class="img-responsive" alt="FOTO FERNANDO GALVÃO PUHL">
                      <span class="employee-name">FERNANDO GALVÃO PUHL</span>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" /> Fone: <a href="tel:4199187.0809">41 99187.0809</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" />
                            <a href="tel:4199187.0809"> 41 99187.0809</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'envelope']" /> Email:
                            <a href="mailto:fernando@galvaovendas.com.br" title="fernando@galvaovendas.com.br">fernando@galvaovendas.com.br</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'home']" /> CRECI: 10966
                        </div>
                    </div>
                </div>

                <div class="row mt-4">             
                    <div class="col container-employee">
                      <img src="/assets/foto_equipe/renata_maria_ribeiro_tarran.png" class="img-responsive" alt="FOTO RENATA MARIA RIBEIRO TARRAN">
                      <span class="employee-name">RENATA MARIA RIBEIRO TARRAN</span>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" /> Fone: <a href="tel:4199258.2728">41 99258.2728</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" />
                            <a href="tel:4199258.2728"> 41 99258.2728</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'envelope']" /> Email:
                            <a href="mailto:renata.tarran@vendasg.com.br" title="renata.tarran@vendasg.com.br">renata.tarran@vendasg.com.br</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'home']" /> CRECI: F19307
                        </div>
                    </div>
                    <div class="col container-employee">
                      <img src="/assets/foto_equipe/silvestre_olenik.jpg" class="img-responsive" alt="FOTO SILVESTRE OLENIK">
                      <span class="employee-name">SILVESTRE OLENIK</span>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" /> Fone: <a href="tel:413014.1101">41 3014.1101</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" />
                            <a href="tel:4199187.1516"> 41 99187.1516</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'envelope']" /> Email:
                            <a href="mailto:silvestre@vendasg.com.br" title="silvestre@vendasg.com.br">silvestre@vendasg.com.br</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'home']" /> CRECI: F5853
                        </div>
                    </div>
                    <div class="col container-employee">
                      <img src="/assets/foto_equipe/jaqueline_muller.jpg" class="img-responsive" alt="FOTO JAQUELINE MULLER">
                      <span class="employee-name">JAQUELINE MULLER</span>
                        <div class="employee-details">
                            <fa :icon="['fas', 'phone-alt']" /> Fone: <a href="tel:4198813.5742">41 98813.5742</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'envelope']" /> Email:
                            <a href="mailto:jaqueline.muller@vendasg.com.br" title="jaqueline.muller@vendasg.com.br">jaqueline.muller@vendasg.com.br</a>
                        </div>
                        <div class="employee-details">
                            <fa :icon="['fas', 'home']" /> CRECI: F28074
                        </div>
                    </div>
                    <div class="col container-employee invisible"></div>
                </div>
            </div>
        </div>
    </section>

    </main-layout>
</template>

<script>
    import MainLayout from './layouts/MainLayout.vue';

    export default {
        components: {
            MainLayout
        }
    }
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.about-image{
    background: url("/assets/imgs/bg-default-1900x634-1.jpg") !important;
    font-family: 'Montserrat';
    background-position: center !important;
    background-size: cover !important;
}

.text-about p{
    font-size: 18px;
}

.services p{
    color: #666666;
    font-size: 14px;
}

h2{
    font-size: 35px;
    color: #333333;
    font-weight: bold;
}

.container-body{
    background-color: rgba(138, 58, 81, 0.4) !important;
    position: relative;
    width: 100%;
    height: 200px;

    .about-image, .about-image-text{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-image {
        background-image: url("/assets/imgs/bg-default-1900x634-1.jpg");
        height: 200px;
        background-size: cover;
        opacity: 0.52;
    }

    .about-image-text {
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        z-index: 10;

        h1 {
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            font-size: 23px;
            letter-spacing: 4px;
            line-height: 31px;
            color: #fff;
            font-weight: 700;
            text-align: center;
        }
    }
}

.about{

    font-family: "Montserrat",sans-serif;

    .text-about{
        p{
            font-size: 18px;
            color: #666666;
            margin-bottom: 0px;
        }
    }

    .services{
        p{
            color: #666666;
            font-size: 14px;
        }

        .item-services {
            text-align: center;
            padding: 100px 10px;
            border-left: 1px solid #8A3A51;
            
            background: linear-gradient(to right, #8a3a51 50%, white 50%);
            background-size: 200% 100%;
            background-position:right bottom;
            transition: all .5s ease;

            h3{
                color: #8a3a51;
                font-size: 14px;
                font-weight: bold;
            }

            h3,p{
                transition: all 1s ease;
            }

            .icon-chart{
                display: inline-block;
                height: 60px;
                width: 60px;
                transition: all .3s ease;
            }

            .icons{
                background: url('https://galvaovendas.com/conteudo-galvao/themes/floripa/images/icons.png');
                margin: 0 auto;
                display: block;
                height: 60px;
                width: 60px;
            }
        }

        .item-services:hover{

            background-position:left bottom;

            h3,p{
            color: #ffffff !important;
            }
        }

        .avaliacao{
            .icon-chart{
                background-position: -70px -100px; //ícone preto
            }

            p{
                margin: 25px 0;
            }

        }

        .avaliacao:hover{
            .icon-chart{
                background-position: -70px -180px; //ícone preto
            }
        }

        .acessoria{
            .icon-chart{
                background-position: 0 -100px; //ícone preto
                // background-position: 0 -180px; //ícone branco
            }

            p{
                margin: 25px 0;
            }
        }

        .acessoria:hover{
            .icon-chart{
                background-position: 0 -180px; //ícone branco
            }
        }

        .consultoria{
            .icon-chart{
                background-position: -140px -100px; //ícone preto
                // background-position: -140px -180px; //ícone branco
            }

            p{
                margin: 25px 0;
            }
        }

        .consultoria:hover{
            .icon-chart{
                background-position: -140px -180px; //ícone branco
            }
        }

    }

}

.container-employee{
    border: 1px solid #ddd;
    padding: 13px 15px 13px 15px;
    margin: 0 10px 20px 10px;
    font-size: 14px;

    a{
        text-decoration: none;
        color: #8a3a51;
    }

    .employee-name{
        margin-top: 5px;
        color: #333;
        font-size: 14px;
        font-weight: bold;
        display: block;
    }

    .img-responsive{
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.container-employee:hover{
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
}

</style>
