
import AdminLayout from '@/screens/layouts/AdminLayout.vue';
import api from '@/config/api';
import {defineComponent} from "vue";


export default defineComponent({
  name: "EditarBuscaExclusiva",
  components: {
    AdminLayout
  },
  data() {
    return {
      DisableNameInput: true,
      paramId: null,
      file: null,
      search: {
        data: [],
        image: null,
        old_image: null,
        loading: false,
        errors: [],
      },
      imageChanged: false,
      empreendimentos: {
        data: [],
        loading: false
      },
      loading: false,
      idValue: null
    }
  },
  computed: {
    url_api() {
      return process.env.VUE_APP_API_URL;
    },
    client_url() {
      return location.origin;
    }
  },
  created() {
    this.paramId = this.$route.params.id;
    this.getExclusiveSearch(this.paramId);
  },
  mounted() {
    this.getEnterprises();
  },
  methods: {
    getExclusiveSearch(id: any) {
      this.search.loading = true;
      api.get('busca-exclusiva/' + id)
          .then(response => {
            this.search.data = response.data.search;
            this.search.image = `${this.url_api}/storage/${response.data.search.logo}`;
            this.search.old_image = response.data.search.logo;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.search.loading = false;
          })
    },
    handleSubmit() {
      this.DisableNameInput = false;
      const form = document.querySelector('#updateBuscaExclusiva') as HTMLFormElement;

      this.loading = true;
      const formData = new FormData(form);
      formData.append("_method", 'PUT'); // Adicionei isso porque não tava conseguindo enviar pelo método put
      if (this.imageChanged) {
        formData.set('company_logo', this.file);
        formData.append('old_image', this.search.old_image);
      }

      api.post('busca-exclusiva/update/' + this.paramId, formData,
          {
            headers: {
              'Content-Type': "multipart/form-data"
            }
          })
          .then(response => {
            console.log(response.data);
          }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false;
        this.DisableNameInput = true;
      })
    },
    uploadImage: function (event: any) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        this.file = input.files[0];
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.search.image = e.target.result;
          this.imageChanged = true;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    getEnterprises(search?: string) {
      if (this.empreendimentos.data.length == 0 || search) {
        this.empreendimentos.loading = true;

        const params = search ? {pesquisa: search} : null
        api.get('/typeenterprises', {params})
            .then(res => {
              const data = res.data.data;
              console.log(data);
              if (data) {
                this.empreendimentos.data = data;
              }
            })
            .finally(() => {
              this.empreendimentos.loading = false;
            })
      }
    },
  }
});
