<template>
  <div v-if="show" class="iframe-container">
    <div id="iframe_360" v-html="activePhoto.HTML"/>
    <div v-if="showNextPrevious" class="buttons">
      <button class="prev-button" @click="nextImage('left')">
        <svg aria-hidden="true" class="svg-inline--fa fa-angle-left short-detail-icon" data-icon="angle-left"
             data-prefix="fas" data-v-27a288b3="" focusable="false" role="img"
             viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">
          <path class=""
                d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                fill="currentColor"></path>
        </svg>
      </button>
      <button class="next-button" @click="nextImage('right')">
        <svg aria-hidden="true" class="svg-inline--fa fa-angle-right short-detail-icon" data-icon="angle-right"
             data-prefix="fas" data-v-27a288b3="" focusable="false" role="img"
             viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">
          <path class=""
                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>
  <div v-else>
    <span>Nenhuma foto 360 graus encontrada.</span>
  </div>
</template>

<script>
import api from "@/config/api";
// variavel usada no metodo nextImage para avancar e voltar as imagens 360
var i = 1;

export default {
  props: {
    referenciaImovel: Number
  },
  data() {
    return {
      show: false,
      showNextPrevious: false,
      activePhoto: {
        HTML: null,
      },
      photos360: [],
    };
  },
  methods: {
    nextImage(direction) {
      var img = this.photos360;
      if (direction === 'right') {
        i = i < img.length - 1 ? i + 1 : 0;
        this.activePhoto = img[i];
      } else {
        i = i > 0 ? i - 1 : img.length - 1;
        this.activePhoto = img[i];
      }
    },
    photos360Exists() {
      if (this.referenciaImovel) {
        api.post('photos-360/get-photos', {
          "referenciaImovel": this.referenciaImovel,
        })
            .then(res => {
              const list = res.data;
              if (list) {
                this.show = true;
                this.activePhoto = list.at(-1);
                list.map(item => {
                  this.photos360.push(item);
                })
                if (list.length > 1) {
                  this.showNextPrevious = true;
                }
                // ajustando a descrição da direita pra ficar alinhada com o iframe.
                document.querySelector('.description-right').style = 'margin-top:73px;padding-top:15px;';
              }
            });
      }
    }
  },
  mounted() {
    this.photos360Exists();
  },
};
</script>
<!--style sem scoped para que o iframe quando for carregado tenha o tamanho especificado.-->
<style>
#iframe_360 iframe {
  width: 100% !important;
  height: 500px !important;
}
</style>
<style scoped>
.iframe-container {
  position: relative;
}

.next-button {
  position: absolute;
  top: 50%;
  right: 0;
  border: none;
  background: #8a3a51;
  color: white;
  font-size: 22px;
}

.prev-button {
  position: absolute;
  top: 50%;
  border: none;
  background: #8a3a51;
  color: white;
  font-size: 22px;
}
</style>
