import { IImageProperty } from '@/config/apiTypes';

/**
 * Format number to cash shape, with thousand dot and decimal comma
 * @param value Normal cash value
 * @returns Formatted cash: 11000000 -> R$ 11.000.000,00
 */
export function formatCashNumber(value: string) {
    try {
        const numberValue = Number(value);

        if(numberValue == 0) {
            return "Sob consulta";
        }
        return "R$ " + numberValue.toLocaleString("pt-BR", { minimumFractionDigits: 2 });    
    } catch(e) {
        console.log(e)
        return "Sob consulta";
    }
}

/**
 * Gets de Url image from Property, if not retry default image.
 */

export function getUrl(object: IImageProperty | Array<IImageProperty>) {
    if(Array.isArray(object)) {
        object = object[0];
    }

    if(object == null || object.Url == null) return 'https://cdn.redeimoveis.com.br/images/imoveis/EM_BREVE';
    return object.Url;
}