import * as VueRouter from 'vue-router';
import Home from '../screens/Home.vue';
import Sobre from '../screens/Sobre.vue';
import Contato from '../screens/Contato.vue';
import Imovel from '../screens/Imovel.vue';
import Login from '../screens/admin/Login.vue';
import Painel from '../screens/admin/Painel.vue';
import Bairros from '../screens/admin/Bairros.vue';
import Photo from '../screens/admin/Photo.vue';
import Tags from '../screens/admin/Tags.vue';
import PainelPromotions from '../screens/admin/PainelPromotions.vue';
import Imprimir from '../screens/Imprimir.vue';
import BuscarImoveis from '../screens/BuscarImoveis.vue';
import TrabalheConosco from '@/screens/TrabalheConosco.vue';
import CadastreImovel from '@/screens/CadastreImovel.vue';
import GerenciarCorretores from '@/screens/GerenciarCorretores.vue';
import NotFound from '@/screens/404.vue';
import AdicionarCorretor from '@/screens/AdicionarCorretor.vue';
import PoliticaDePrivacidade from '../screens/PoliticaDePrivacidade.vue';
import BuscaExclusiva from '../screens/admin/BuscaExclusiva.vue';
import EditarBuscaExclusiva from '../screens/EditarBuscaExclusiva.vue';
import BuscaExclusivaPainel from "@/screens/admin/BuscaExclusivaPainel.vue";
import ImoveisLitoral from "@/screens/ImoveisLitoral.vue";
import Promotions from "@/screens/Promotions.vue";
import api from "@/config/api";
import ImoveisLitoralSC from '@/screens/ImoveisLitoralSC.vue';
// import CorretoresDeImoveis from '@/screens/CorretoresDeImoveis.vue';
// import Corretor from '@/screens/Corretor.vue';


type routeFiltersType = {
    lancamento: {
        lancamento: 'Sim' | 'Nao'
    },
    imovelPronto: {
        imovelPronto: 'Sim' | 'Nao'
    },
    novosNegocios: {
        tipos: string[]
    }
    promocao: {
        promocao: 'Sim' | 'Nao'
    }
};


const mainRoutes: VueRouter.RouteRecordRaw[] = [
    {path: '/', name: 'home', component: Home},
    {path: '/:name', name: 'construtoras', component: BuscaExclusivaPainel},
    {path: '/busca', name: 'buscar-imoveis', component: BuscarImoveis},
    {path: '/litoral', name: 'buscar-imoveis-litoral', component: ImoveisLitoral},
    {path: '/litoral-sc', name: 'litoral-sc', component: ImoveisLitoralSC},
    {path: '/sobre', name: 'sobre', component: Sobre},
    {path: '/politica-de-privacidade', name: 'politica-de-privacidade', component: PoliticaDePrivacidade},
    {path: '/trabalhe-conosco', name: 'trabalhe-conosco', component: TrabalheConosco},
    {path: '/cadastre-seu-imovel', name: 'cadastre-seu-imovel', component: CadastreImovel},
    {path: '/contato', name: 'contato', component: Contato},
    {path: '/imovel/:id/:slug', name: 'imovel', component: Imovel},
    {path: '/imprimir', name: 'imprimir', component: Imprimir},
    {path: '/promocao', name: 'promocoes', component: Promotions},
    {path: '/admin/login', name: 'login', component: Login},
    {path: '/admin/painel', name: 'painel', component: Painel},
    {path: '/admin/painel/bairros', name: 'bairros', component: Bairros},
    {path: '/admin/painel/criar-busca-exclusiva', name: 'busca-exclusiva', component: BuscaExclusiva},
    {path: '/admin/painel/busca-exclusiva/editar/:id', name: 'editar-busca-exclusiva', component: EditarBuscaExclusiva},
    {path: '/admin/painel/tags', name: 'tags', component: Tags},
    {path: '/admin/painel-promocoes', name: 'painel-promocoes', component: PainelPromotions},
//    { path: '/admin/gerenciar-corretores', name: 'gerenciar-corretores', component: GerenciarCorretores },
    // { path: '/corretor', name: 'corretor', component: Corretor },
    {
        path: '/admin/gerenciar-corretores',
        name: 'gerenciar-corretores',
        component: GerenciarCorretores,
        meta: {title: `Gerenciar Corretores`}
    },
    {path: '/admin/gerenciar-corretores/add', name: 'adicionar-corretor', component: AdicionarCorretor},
    {path: '/admin/gerenciar-corretores/add/:id', name: 'editar-corretor', component: AdicionarCorretor},
    {path: '/:catchAll(admin.*)', redirect: {name: 'login'}},
    {path: '/:catchAll(.*)', name: 'not-found', component: NotFound},
];

const routeFilters: routeFiltersType = {
    lancamento: {lancamento: 'Sim'},
    imovelPronto: {imovelPronto: 'Sim'},
    promocao: {promocao: 'Sim'},
    novosNegocios: {
        tipos: [
            'Casa comercial',
            'Galpão',
            'Loja',
            'Ponto Comercial',
            'Salas/Conjuntos',
            'Studio',
            'Terreno Comercial'
        ]
    }
};

const mainRouter = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: mainRoutes
});

function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

async function getTags(name: string) {
    await api.get('/tags/' + name)
        .then(response => {
            Object.keys(response.data.tags).forEach(function (item) {
                const tagsSite = document.createElement('meta');
                tagsSite.setAttribute('name', response.data.tags[item].title)
                tagsSite.setAttribute('content', response.data.tags[item].description)
                if (response.data.tags[item].keywords != '')
                    tagsSite.setAttribute('property', response.data.tags[item].keywords)
                document.head.appendChild(tagsSite)
            })
        }).catch((error) => {
            console.error(error);
        })
}
mainRouter.afterEach(() => {
    // Rola suavemente para o topo da página
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
  
mainRouter.beforeEach(async (to, from, next) => {
    if (to.path === '/' && isMobileDevice()) {
        next({ name: 'buscar-imoveis' });
    }

    const tagRobots = document.createElement('meta');

    const tag = document.createElement('meta');
    tag.setAttribute('name', 'description');

    let content;

    //NOINDEX
    if (to.name == 'imovel') {
        tagRobots.setAttribute('name', 'robots');
        tagRobots.setAttribute('content', 'noindex');
        document.head.appendChild(tagRobots);
    }
    //SEO
    else if (to.name == 'home') {
        document.title = 'Imobiliária em Curitiba - Imóveis à Venda | Galvão Vendas';
        content = 'Somos especialistas em venda de imóveis em Curitiba. Casas, Apartamentos, Sobrados, Terrenos e imóveis comerciais. Imóveis selecionados em Curitiba e região metropolitana. Galvão Vendas há mais de 60 anos realizando sonhos.';
    } else if (to.path == '/litoral') {
        await getTags('litoral');
    } else if (to.path == '/alugar') {
        await getTags('alugar');
    } else if (to.path == '/lancamentos') {
        await getTags('lancamentos');
    } else if (to.path == '/busca') {
        //busca = novo-negocios
        await getTags('novos-negocios');
    } else if (to.name == 'sobre') {
        await getTags('sobre');
        document.title = 'Há mais de 60 anos vendendo imóveis  | Galvão Vendas';
        content = 'Nós, da Galvão Vendas, acreditamos que a nossa missão como profissionais do mercado imobiliário é ficar atentos a esta busca de cada indivíduo, às conexões que surgem no cotidiano, sejam relações afetivas como comerciais.';
    } else if (to.name == 'politicadeprivacidade') {
        document.title = 'Política de Privacidade  | Galvão Vendas';
        content = 'Estamos comprometidos em resguardar sua privacidade, por isso esclarecemos quais são as informações coletadas dos usuários de nossos sites.';
    } else if (to.name == 'buscar-imoveis' && to.query.lancamento == "Sim") { //lancamentos
        document.title = 'Apartamentos e imóveis em lançamento | Galvão Vendas';
        content = 'A Galvão Vendas traz os melhores lançamento de imóveis em Curitiba. Apartamentos, sobrados e casas em condomínio. Com corretores preparados para ajudar você a realizar seu sonho.';
    } else if (to.name == 'buscar-imoveis' && to.query.lancamento == undefined) { //novos negocios
        document.title = 'Salas, conjuntos e imóveis comerciais à venda | Galvão Vendas';
        content = 'Procurando comprar sala, casa, conjunto, barracõão comercial? Fale com a Galvão Vendas. Temos uma grande diversidade de imóveis comerciasi em Curitiba e Região Metropolitana. Fale com nosso corretores e encontre a opção para seu negócio.';
    } else if (to.name == 'contato') {
        await getTags('contato');
        document.title = 'Fale com a Galvão Vendas | Galvão vendas';
        content = 'Fale com a Galvão Vendas. Estamos prontos para ajudar você a comprar ou vender seu imóvvel em Curitiba e na Região Metropolitana.';
    } else if (to.name == 'trabalhe-conosco') {
        await getTags('trabalhe-conosco');
        document.title = 'Vagas de Emprego | Galvão Vendas';
        content = 'Venha fazer parte do imóbiliária mais experiente de Curitiba. Cadastre seu currículo e venha fazer parte de nosso time.';
    } else if (to.name == 'cadastre-seu-imovel') {
        await getTags('cadastre-seu-imovel');
        document.title = 'Venda seu imóvel em curitiba e região metropolitana | Galvão Vendas';
        content = 'Ofereça seu imóvel para venda com que tgem mais de 60 anos de experiência na corretagem imobiliária. Com uma vasta carteira de clientes e corretores capacitados em rapidamente encontrar o melhor negócio para vender seu imóvel.';
    } else {
        document.title = 'Galvão';
        content = '';
    }

    if (to.name != 'imovel')
        document.getElementsByTagName('meta')[<any>"description"].content = content;

    // const allowedOrigins = [
    //   'lourdes',
    //   'azevedo',
    //   'emiliano',
    //   'celles',
    //   'silvestre',
    //   'renata',
    //   'fernando',
    // ];
    // const subdomain = window.location.host.split('.')[0];

    // if(!allowedOrigins.includes(subdomain) && !subdomain.includes('galvaovendas') && !subdomain.includes('localhost')){
    //   next({ name: 'not-found' })
    // } else{
    next();
    // }

});

export {mainRouter, routeFilters, routeFiltersType};
