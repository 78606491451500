
import {defineComponent} from 'vue';
import MainLayout from '@/screens/layouts/MainLayout.vue';
import NotFound from '@/screens/404.vue';
import api from '@/config/api';
import {IImageProperty} from '@/config/apiTypes';
import {getUrl} from '@/utils/functions';
import allowedSubdomains from '@/config/allowedSubdomains';
import Photo360 from '@/components/Photo360.vue'

type formData = {
  codigo_imovel: number;
  referencia: string;
  dia: Date;
  hora: number;
  nome: string;
  email: string;
  email_corretor: string;
  telefone: string;
}

type VariablesData = {
  Titulo: string;
  Bairro: string;
  Endereco: string;
  Numero: string;
  Complemento: string;
  Cidade: string;
  CodigoImovel: number;
  Descricao: string;
  Tipo: string;
  Referencia: string,
  URLAmigavel: string;
  AreaTotal: number;
  IPTU: number;
  Fotos: IImageProperty[];
  Quartos: number;
  Suites: number;
  AreaPrivativa: number;
  AreaTerreno: number;
  Vagas: number;
  Banheiros: number;
  Caracteristicas: Array<string>;
  Infraestrutura: Array<string>;
  Andares: string;
  ValorImovel: string;
  photoContainerWidth: string;
  PhotosURL: Array<string>;
  DisablePrevNext: boolean;
  interval: number;
  carouselOnGoing: boolean;
  Similares: Array<string>;
  MensagemModal: string;
  visitSucess: string;
  tableBairros: Array<string>;
  IsReservado: number;
}

    type DataTypes = {
        center: {
            lat: number;
            lng: number;
        };
        markers: any[];
        formData: formData;
        dados: VariablesData;
        leadMessage: string;
        errors: string[];
        loadingMessage: string;
        loading: boolean;
        notFound: boolean;
    }

    type ApiResponseContactJson = {
        message: string;
        errors?: string[];
    }
    export default defineComponent({
        data(): DataTypes {
            return {
                center: {lat: null, lng: null},
                markers: [{position: {lat: null, lng: null}}],
                formData: {
                    codigo_imovel: null,
                    referencia: null,
                    dia: null,
                    hora: null,
                    nome: null,
                    email: null,
                    email_corretor: null,
                    telefone: null
                },
                dados: {
                    Titulo: null,
                    Bairro: null,
                    Endereco: null,
                    Numero: null,
                    Complemento: null,
                    Cidade: null,
                    CodigoImovel: null,
                    Descricao: null,
                    Tipo: null,
                    Referencia: null,
                    URLAmigavel: null,
                    AreaTotal: null,
                    IPTU: null,
                    Fotos: null,
                    Quartos: null,
                    Suites: null,
                    AreaPrivativa: null,
                    AreaTerreno: null,
                    Vagas: null,
                    Banheiros: null,
                    Caracteristicas: [],
                    Infraestrutura: [],
                    Andares: null,
                    ValorImovel: null,
                    photoContainerWidth: null,
                    PhotosURL: [],
                    DisablePrevNext: false,
                    interval: null,
                    carouselOnGoing: false,
                    Similares: [],
                    MensagemModal: null,
                    visitSucess: null,
                    tableBairros: [],
                    IsReservado: null,
                },
                leadMessage: "",
                errors: [],
                loading: false,
                loadingMessage: "Procurando por ficha do imóvel...",
                notFound: false
            }
        },
        computed: {
            location: () => {return window.location.href;}
        },
        components: {
            MainLayout,
            NotFound,
            Photo360       
        },
        methods: {
            getUrl,
            getPropertie(): void {
                this.loading = true;
                this.showErrors({} as ApiResponseContactJson);

                api.get('properties/'+this.$route.params.id)
                .then(response => {
                    if(response.data.data.URLAmigavel != this.$route.params.slug){
                        this.$router.push('/imovel/'+response.data.data.CodigoImovel+'/'+response.data.data.URLAmigavel+'/');
                    }

                    ////Popular dados que aparecem no front
                    this.dados.Referencia = response.data.data.Referencia??'';
                    this.dados.Titulo = response.data.data.Titulo??'';
                    this.dados.Bairro = response.data.data.Bairro??'';
                    this.dados.Endereco = response.data.data.Endereco??'';
                    this.dados.Numero = response.data.data.Numero??'';
                    this.dados.Complemento = response.data.data.Complemento??'';
                    this.dados.Cidade = response.data.data.Cidade??'';
                    this.dados.CodigoImovel = response.data.data.CodigoImovel??'';
                    this.dados.Tipo = response.data.data.Tipo??'';
                    this.dados.ValorImovel = response.data.data.ValorImovel??'';
                    this.dados.Descricao = response.data.data.Descricao??'';
                    this.dados.Referencia = response.data.data.Referencia??'';
                    this.dados.IsReservado = response.data.data.IsReservado??'';
                    this.center.lat = response.data.data.Latitude??'';
                    this.center.lng = response.data.data.Longitude??'';
                    this.markers[0].position.lat = response.data.data.Latitude??'';
                    this.markers[0].position.lng = response.data.data.Longitude??'';
                    
                    //Substituindo <BR> por <br> da descrição
                    let desc = response.data.data.Descricao??''
                    let re = /<BR>/gi;
                    this.dados.Descricao = desc.replace(re, "<br>")

                    const campos = response.data.data.campos;

                    //Lista de campos que fazem parte das Características - CodigoTipoCampo = 5
                    const caracteristicas = [
                        'Área total',
                        'Área privativa',
                        'Andar',
                        'Aptos. por andar',
                        'Valor condomínio',
                        'Salas',
                        'Conj. por andar',
                        'M² construído',
                        'Portaria 24h',
                        'Pavimentos',
                        'M² pátio',
                        'Pé-direito',
                        'Área de serviço',
                        'BWC Social',
                        'BWC empregada',
                        'Churrasqueiras',
                        'Copa',
                        'Cozinhas',
                        'Idade do imóvel',
                        'Quartos',
                        'Sacadas',
                        'Suítes',
                        'Vagas de garagem',
                        'Closet',
                        'Lavabos',
                        'Sala de jantar',
                        'Sala estar íntima',
                        'Lareira',
                        'Sala de escritório',
                        'Sala biblioteca',
                        'Dep. Empregada',
                        'Núm de pavimentos',
                        'Aptos por andar',
                        'Elevadores',
                        'Prazo (meses)',
                        'Núm de prestações',
                        'Núm prestações pagas',
                        'Status',
                        'Vagas de estacionamento',
                        'Demi suíte',
                        'Área privativa do terreno'
                    ];

                    //Lista de campos que fazem parte da Infraestrutura  - CodigoTipoCampo = 2
                    const infraestrutura = [
                        'Portaria',
                        'Cobertura',
                        'Duplex',
                        'Garden',
                        'Sacada',
                        'ativa',
                        'Mobiliado',
                        'Piscina',
                        'Portaria 24h',
                        'Condomínio fechado',
                        'Comercial',
                        'Área construída',
                        'Pátio',
                        'Estacionamento',
                        'Aquecimento',
                        'Central de gás',
                        'Aceita carro na entrada',
                        'Forro',
                        'Playground',
                        'Salão de festas',
                        'Salão de jogos',
                        'Sistema de ar',
                        'Sistema de incêndio',
                        'Sistema de CFTV',
                        'Sistema de segurança',
                        'Telhado',
                        'Quitado',
                        'Aceita proposta',
                        'Aceita automóvel',
                        'Aceita imóvel',
                        'Água',
                        'Esgoto',
                        'Luz',
                        'Telefone',
                        'Placa no local',
                        'Depósito',
                        'Canil',
                        'Cancha de esportes',
                        'Veiculação restrita',
                        'Restrito à imobiliária',
                        'Churrasqueira priv',
                        'Vendido',
                        'Despensa',
                        'Reservado',
                        'Churrasqueira coletiva',
                        'Adega',
                        'Ático',
                        'Jardim de inverno',
                        'SPA',
                        'Quiosque',
                        'Cinema',
                        'Bicicletário',
                        'Brinquedoteca',
                        'Fitness',
                        'Pista de caminhada',
                        'Sauna',
                        'Piscina infantil',
                        'Piscina Coletiva',
                        'Sala Fitness',
                        'Campo',
                        'Hidro',
                        'Portão eletrônico',
                        'Interfone',
                        'Alarme',
                        'Cerca elétrica',
                        'Grades',
                        'Cozinha com armários',
                        'Dorms. com armários',
                        'Espaço Gourmet',
                        'Aquecimento solar',
                        'Edícula',
                        'Quintal',
                        'Jardim',
                        'Aquecimento Elétrico',
                        'Animal estimação',
                        'Estuda permuta',
                        'Lançamento',
                        'Re-divulgação'
                    ];

                    let banheirosTotal = 0;
                    //campos adicionais
                    campos.forEach((value: any) => {
                        if(value.Campo == 'Quartos')
                            this.dados.Quartos = value.pivot.Valor;
                        else if (value.Campo === 'BWC Social' || value.Campo === 'BWC empregada' || value.Campo === 'Demi suíte'
                            || value.Campo === 'Lavabos' || value.Campo === 'Suítes')
                        {
                            banheirosTotal += parseInt(value.pivot.Valor);
                            if (value.Campo == 'Suítes')
                                this.dados.Suites = value.pivot.Valor;  
                        }
                        else if(value.Campo == 'Área total')
                            this.dados.AreaTotal = value.pivot.Valor;
                        else if(value.Campo == 'Área privativa')
                            this.dados.AreaPrivativa = value.pivot.Valor;
                        else if(value.Campo == 'Área do terreno')
                            this.dados.AreaTerreno = value.pivot.Valor;
                        else if(value.Campo == 'Vagas de garagem')
                            this.dados.Vagas = value.pivot.Valor;
                        else if(value.Campo == 'IPTU')
                            this.dados.IPTU = value.pivot.Valor;
                        else if(value.Campo == 'Andar')
                            this.dados.Andares = value.pivot.Valor;
                        //CARACTERÍSTICAS
                        else if(caracteristicas.includes(value.Campo)){
                            if(value.Campo == "Valor condomínio")
                                value.pivot.Valor = this.formatPrice(value.pivot.Valor)??'';
                                
                            this.dados.Caracteristicas.push(value.Campo+": "+value.pivot.Valor);
                        }
                        //INFRAESTRUTURA
                        else if(infraestrutura.includes(value.Campo)){
                            if(value.Campo == "Cancha de esportes")
                                value.Campo = "Quadra de esportes";

                            this.dados.Infraestrutura.push(value.Campo);
                        }
                    });

                    this.dados.Banheiros += banheirosTotal;

                    //Fotos
                    this.dados.Fotos = response.data.data.fotos??'';
                    let counter = 0;
                    this.dados.Fotos.forEach((value: any) => {
                        counter++;
                        let url = getUrl(value);
                        if(url != '')
                            this.dados.PhotosURL.push(url);
                    });
                    // console.log(this.dados.PhotosURL);
                    this.dados.photoContainerWidth = "width: " + (counter * 60) + "vw";

                    this.dados.Similares = response.data.data.similares??'';
                    this.updateMetaData();

                })
                .catch(() => {
                    this.notFound = true;
                })
                .finally(() => {
                    this.loading = false;
                }); 

            },
            showErrors(data: ApiResponseContactJson): void {
                this.leadMessage = data.message;
                this.errors = data.errors || [];
            },
            sendScheduleVisitForm(): void {
                this.loading = true;
                this.loadingMessage = "Agendando visita...";
                this.showErrors({} as ApiResponseContactJson);

                document.getElementById('btnClose').click();
                
                this.formData.codigo_imovel = this.dados.CodigoImovel;
                this.formData.referencia = this.dados.Referencia;

                api
                .post<ApiResponseContactJson>('schedule-visit', this.formData)
                .then(res => {
                    
                    this.formData = {} as formData;
                    // this.showErrors(res.data);
                    let response:any = res.data;

                    console.log(response.message);
                    if(response.message == "Já existe uma visita marcada para este dia e horário."){
                        this.dados.MensagemModal = response.message; 
                        this.dados.visitSucess = 'bg-danger';
                    }else if(response.message == 'Sua solicitação de visita foi enviada com sucesso!'){
                        this.dados.MensagemModal = response.message; 
                        this.dados.visitSucess = 'bg-success';
                    } else{
                        this.dados.MensagemModal = 'Ocorreu um erro na tentativa de marcar esta visita. Entre em contato conosco se possível!'; 
                        this.dados.visitSucess = 'bg-danger';
                    }
                })
                .catch((error) => {
                    console.log(error);
                    const data: ApiResponseContactJson = error.response || {
                        message: "Houve um erro ao processar requisição"
                    };

                    this.dados.MensagemModal = 'Ocorreu um erro na tentativa de marcar esta visita. Entre em contato conosco se possível!'; 
                    this.dados.visitSucess = 'bg-danger';
                
                    this.showErrors(data);
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingMessage = "Procurando por ficha do imóvel...";
                });
            },
            formatPrice(value: number) {
                let val = (value/1).toFixed(2).replace('.', ',')
                return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            htmlText(text: string){
                return `<p> ${text} </p>`;
            },
            prev(isButton: boolean){

                if(isButton){
                    this.stopCarousel();
                }
                
                let active = document.getElementsByClassName('active')[0] as HTMLElement;

                let prev;
                if(active){
                    prev = active.previousSibling as HTMLElement;

                    if(prev!=null){
                        active.classList.remove("active");
                        prev.classList.add("active");
                        let photos = document.getElementsByClassName('photos')[0] as HTMLElement;
                        let x = photos.getBoundingClientRect().x;
                        photos.style.transform = `translate(calc(${x}px + 40vw))`; 
                    }
                }

                if(isButton){
                    this.startCarousel();
                    // setTimeout(this.startCarousel(),5000);
                }
            },
            next(isButton: boolean){

                if(isButton){
                    // console.log('isButton');
                    this.stopCarousel();
                }

                // this.dados.DisablePrevNext = true;
                let photos = document.getElementsByClassName('photos')[0] as HTMLElement;
                let active = document.getElementsByClassName('active')[0] as HTMLElement;
                let next;

                if(active instanceof HTMLElement){
                    next = active.nextSibling as HTMLElement;

                    if(next instanceof HTMLElement){
                        // console.log("elementoChild->",next);
                        active.classList.remove("active");
                        next.classList.add("active");
                        let x = photos.getBoundingClientRect().x;
                        photos.style.transform = `translate(calc(${x}px - 80vw))`; 
                    }else{
                        photos.style.transform = `translate(0px)`; 
                        active.classList.remove("active");
                        this.firstChildActive();
                    }
                } else{
                    this.firstChildActive();
                }

                if(isButton){
                    this.startCarousel();
                    // setTimeout(this.startCarousel(),5000);
                }
            },
            firstChildActive(){
                    let photos = document.getElementsByClassName('photos')[0] as HTMLElement;
                    if(photos && photos.firstElementChild){
                        let firstElement = photos.firstElementChild;
                        firstElement.classList.add("active");
                    }
            },
            showImagesGrid(){
                let grid = document.getElementsByClassName('grid')[0] as HTMLElement;
                grid.style.display = "block";
                let photos = document.getElementsByClassName('galery')[0] as HTMLElement;
                photos.style.display = "none";
            },
            showCarouselGrid(){
                let photos = document.getElementsByClassName('galery')[0] as HTMLElement;
                photos.style.display = "block";
                let grid = document.getElementsByClassName('grid')[0] as HTMLElement;
                grid.style.display = "none";
            },
            startCarousel(){
                if(!this.dados.carouselOnGoing){
                    this.dados.carouselOnGoing = true;
                    // this.dados.DisablePrevNext = true;
                    let btnPrev = document.getElementsByClassName('button-prev')[0] as HTMLButtonElement;
                    let btnNext = document.getElementsByClassName('button-next')[0] as HTMLButtonElement;
                    btnPrev.disabled = true;
                    btnNext.disabled = true;
                    setTimeout(function(){},10000);
                    btnPrev.disabled = false;
                    btnNext.disabled = false;
                    setTimeout(function(){},4500);
                    this.dados.interval = setInterval(this.next,5000);
                }
                return function(){};
            },
            stopCarousel(){
                if(this.dados.carouselOnGoing){
                    clearInterval(this.dados.interval);
                    this.dados.carouselOnGoing = false;
                }
            },
            getRealtor(){

                api
                .get('/agent/getAgents')
                .then(res => {
                    
                    const subdomain = window.location.host.split('.')[0];

                    if(allowedSubdomains.includes(subdomain) && !subdomain.includes('galvaovendas') && !subdomain.includes('localhost')){
                        const corretor = res.data.data;
                        
                        corretor.forEach((value: any)=> {

                            if(value.corretor_nome.toUpperCase().includes(subdomain.toUpperCase())){
                                this.formData.email_corretor = value.corretor_email;
                            }
                        })

                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },
            getTableBairros(){
                api
                .get('districts')
                .then(res => {
                    this.dados.tableBairros = res.data.tableBairros;
                })
                .catch(err => {
                    console.log(err)
                })
            },
            getBairroPai(bairro: string){
                //Obtendo bairro pai, caso houver
                this.dados.tableBairros.forEach((value: any) => {
                    if(value.bairro == bairro && value.id_bairro_pai){
                        this.dados.tableBairros.forEach((val: any) => {
                            if(val.id == value.id_bairro_pai)
                                bairro = val.bairro; //Substitui pelo bairro pai
                        });
                    }
                });
                return bairro;
            },
            updateMetaData() { 
                const metaTags = [
                    { property: 'twitter:image' ,content: this.dados.PhotosURL[0] },
                    { property: 'twitter:description' ,content: this.dados.Descricao },
                    { property: 'twitter:title' ,content: this.dados.Titulo },
                    { property: 'twitter:card', content: 'summary_large_image' },
                    { property: 'og:title', content: this.dados.Titulo },
                    { property: 'og:description', content: this.dados.Descricao },
                    { property: 'og:image', content: this.dados.PhotosURL[0] },
                    { property: 'og:type', content: 'website' }
                ];

                metaTags.forEach(tag => {
                    let metaTag = document.querySelector(`meta[property="${tag.property}"]`);
                    if (!metaTag) {
                        metaTag = document.createElement('meta');
                        metaTag.setAttribute('property', tag.property);
                        document.head.insertBefore(metaTag, document.head.firstChild);
                    }
                    metaTag.setAttribute('content', tag.content);
                });
            }
        },
        beforeMount() {
            this.getTableBairros();
            this.getPropertie();
            this.getTableBairros();
            setTimeout(this.startCarousel(),5000);
            this.getRealtor();
        },
        mounted() {
        }
      });


