
    import { defineComponent } from "vue";

    export default defineComponent({
        props: {
            link: String,
            visible: Boolean
        },
        watch: {
            visible(value) {
                const blockScroll = (e: Event) => e.preventDefault();
                if(value) {
                    return window.addEventListener('scroll', blockScroll);
                }
                window.removeEventListener('scroll', blockScroll);
            }
        }
    });
