import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_floating_buttons = _resolveComponent("floating-buttons")!
  const _component_cookies_warning = _resolveComponent("cookies-warning")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_ctx.$route.path == '/:catchAll(admin.*)')
      ? (_openBlock(), _createBlock(_component_floating_buttons, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$route.path.indexOf('/admin') != 0)
      ? (_openBlock(), _createBlock(_component_cookies_warning, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}