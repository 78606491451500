
import {IPagination, IProperties, IResponse, IFieldProperty} from '@/config/apiTypes';
import {formatCashNumber} from '@/utils/functions';
import {defineComponent} from 'vue';
import api, {getCookie} from '@/config/api';
import MultipleSelect from '@/components/input/MultipleSelect.vue';
import MultipleCheck from '@/components/input/MultipleCheck.vue';
import PropertyCard from '@/components/PropertyCard.vue';
import SearchSelect from '@/components/input/SearchSelect.vue';
import AdminLayout from '@/screens/layouts/AdminLayout.vue';
import Pagination from '@/components/Pagination.vue';
import ShareModal from '@/components/ShareModal.vue';
import Slider from '@vueform/slider';
import '@vueform/slider/themes/default.css';
import PropertyEditModal from '@/components/PropertyEditModal.vue';


type ApiResponseContactJson = {
  message: string;
  errors?: string[];
}

interface IFilters<T> {
  data: T[];
  loading: boolean;
}

declare interface dataType {
  leadMessage: string;
  errors: string[];
  loading: boolean;
  activeCheckboxId: string | null;
  tipos: IFilters<{ Tipo: string }>;
  bairros: IFilters<{ Bairro: string }>;
  cidades: IFilters<{ Cidade: string }>;
  empreendimentos: IFilters<{ Empreendimento: string }>;
  tipoEmpreendimentos: IFilters<{ TipoEmpreendimento: string }>;
  sliderMin: number;
  sliderMax: number;
  shareModal: {
    visible: boolean;
    link: string;
  };
  filterInput: {
    tipo: Array<string>;
    cidade: Array<string>;
    bairro: Array<string>;
    codigo: Array<number>;
    referencia: string;
    dormitorios: Array<number>;
    banheiros: Array<number>;
    suites: Array<number>;
    vagas: Array<number>;
    valor: Array<number>;
    metragem: Array<number>;
    ordem: string;
    empreendimento: Array<string>;
    tipoEmpreendimento: Array<string>;
    page: number;
    restrito: number;
  };
  imoveis: {
    data: IProperties[];
    error: string | null;
    loading: boolean;
    pagination: IPagination;
  };
  //ID's dos banners e lista destaques
  selectedPromotions: Array<number>;
  isModalVisible: boolean;
  property: Array<string>;
}

export default defineComponent({
  setup() {
    return {formatCashNumber};
  },
  components: {
    Slider,
    AdminLayout,
    ShareModal,
    PropertyCard,
    MultipleSelect,
    MultipleCheck,
    Pagination,
    SearchSelect,
    PropertyEditModal
  },
  mounted() {
    this.isLogged();
  },
  beforeMount() {
    if (api.defaults.headers.common['Authorization'] != "") {
      const cookie = getCookie('Authorization')
      if (cookie)
        api.defaults.headers.common['Authorization'] = cookie;
      else
        this.$router.push('login')
    }

    this.getProperties();
    this.getPromotions();
  },
  data(): dataType {
    return {
      leadMessage: "",
      errors: [],
      loading: true,
      activeCheckboxId: null,
      tipos: {
        data: [],
        loading: false
      },
      cidades: {
        data: [],
        loading: false
      },
      bairros: {
        data: [],
        loading: false
      },
      empreendimentos: {
        data: [],
        loading: false
      },
      tipoEmpreendimentos: {
        data: [],
        loading: false
      },
      shareModal: {
        visible: false,
        link: ''
      },
      sliderMin: 0,
      sliderMax: 110000000,
      filterInput: {
        tipo: [],
        cidade: [],
        bairro: [],
        codigo: [],
        referencia: "",
        dormitorios: [],
        banheiros: [],
        suites: [],
        vagas: [],
        valor: [0, 11000000],
        metragem: [0, 600],
        ordem: "",
        empreendimento: [],
        tipoEmpreendimento: [],
        page: 1,
        restrito: 0,
      },
      imoveis: {
        data: [],
        error: null,
        loading: false,
        pagination: null
      },
      //ID's dos banners e lista destaques
      selectedPromotions: [],
      isModalVisible: false,
      property: [],
    };
  },
  methods: {
    showModal(imovel: string[]) {
      this.property = imovel;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    loadingModal() {
      this.closeModal();
      this.getProperties();
    },
    isLogged() {
      api
          .get<ApiResponseContactJson>('auth/painel')
          .then(res => {
            this.showErrors(res.data);
          })
          .catch((error) => {
            console.error(error)
            this.$router.push('login');
          })
          .finally(() => {
            this.loading = false
          });
    },
    showCheckboxes(checkboxDivId: string): void {
      this.activeCheckboxId = this.activeCheckboxId == checkboxDivId ? null : checkboxDivId;
      window.onclick = (e: MouseEvent) => {
        var target = e.target as HTMLElement;
        if (!target.closest(".multiple-select"))
          this.activeCheckboxId = null;
      };
    },
    logout() {
      this.delete_cookie('Authorization');
      this.$router.push('login');
    },
    delete_cookie(name: string) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    saveNewPromotions(): void {
      this.loading = true;
      this.showErrors({} as ApiResponseContactJson);
      api
          .post<ApiResponseContactJson>('auth/promotions', {
            'promotion': this.selectedPromotions
          })
          .then(res => {
            this.showErrors(res.data);
          })
          .catch((error) => {
            const data: ApiResponseContactJson = error.response.data || {
              message: "Houve um erro ao processar requisição"
            };
            this.showErrors(data);
          })
          .finally(() => {
            this.getProperties();
            this.loading = false;
          });
    },
    filterByPromotions() {
      this.imoveis.loading = true;
      this.showErrors({} as ApiResponseContactJson);

      this.filterInput.codigo = this.arrayUnique(this.selectedPromotions);
      this.getProperties(1);
    },
    getPromotions() {
      api
          .get('auth/promotions-ids')
          .then(res => {
            this.selectedPromotions = res.data.list;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.imoveis.loading = false;
          })
    },
    showErrors(data: ApiResponseContactJson): void {
      this.leadMessage = data.message;
      this.errors = data.errors || [];
    },
    formatValue(value: number) {
      return value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
          });
    },
    formatFootage(value: number) {
      return `M² ${Math.round(value)}`
    },
    getTypes() {
      if (this.tipos.data.length == 0) {
        this.tipos.loading = true;
        api.get('/types')
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.tipos.data = data;
              }
            })
            .finally(() => {
              this.tipos.loading = false;
            })
      }
    },
    getCities() {
      if (this.cidades.data.length == 0) {
        this.cidades.loading = true;
        api.get('/cities/tops')
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.cidades.data = data;
              }
            })
            .finally(() => {
              this.cidades.loading = false;
            })
      }
    },
    getEnterprises(search?: string) {
      if (this.empreendimentos.data.length == 0 || search) {
        this.empreendimentos.loading = true;
        const params = search ? {pesquisa: search} : null
        api.get('/enterprises', {params})
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.empreendimentos.data = data;
              }
            })
            .finally(() => {
              this.empreendimentos.loading = false;
            })
      }
    },
    getTypeEnterprises(search?: string) {
      if (this.tipoEmpreendimentos.data.length == 0 || search) {
        this.tipoEmpreendimentos.loading = true;
        const params = search ? {pesquisa: search} : null
        api.get('/typeenterprises', {params})
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.tipoEmpreendimentos.data = data;
              }
            })
            .finally(() => {
              this.tipoEmpreendimentos.loading = false;
            })
      }
    },
    getDistricts() {
      if (this.bairros.data.length == 0) {
        this.bairros.loading = true;
        api.get('/districtsSite?todos=Sim')
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.bairros.data = data;
              }
            })
            .finally(() => {
              this.bairros.loading = false;
            })
      }
    },
    getProperties(page?: number, isMainSearch?: boolean) {
      this.imoveis.loading = true;
      if (isMainSearch)
        this.filterInput.codigo = []; //Limpando filtro dos destaques

      if (page) {
        this.filterInput.page = page;
      }
      let codigo = this.$route.query.codigo;
      let referencia = this.$route.query.referencia;
      let valmin = this.$route.query.valormin;
      let valminInt: number = +valmin;
      let valmax = this.$route.query.valormax;
      let valmaxInt: number = +valmax;
      let metmin = this.$route.query.metragemmin;
      let metminInt: number = +metmin;
      let metmax = this.$route.query.metragemmax;
      let metmaxInt: number = +metmax;
      let empreendimento = this.$route.query.empreendimento;
      let restrito = this.$route.query.restrito;

      const params = {
        ...this.filterInput,
        codigo: this.$route.query.codigo ? codigo : this.filterInput.codigo[0] == null ? null : this.filterInput.codigo,
        referencia: this.$route.query.referencia ? referencia : this.filterInput.referencia == "" ? null : this.filterInput.referencia,
        dormitorios: this.$route.query.dormitorios ? [this.$route.query.dormitorios] : this.filterInput.dormitorios[0] == null ? null : this.filterInput.dormitorios,
        banheiros: this.$route.query.banheiros ? [this.$route.query.banheiros] : this.filterInput.banheiros[0] == null ? null : this.filterInput.banheiros,
        suites: this.$route.query.suites ? [this.$route.query.suites] : this.filterInput.suites[0] == null ? null : this.filterInput.suites,
        vagas: this.$route.query.vagas ? [this.$route.query.vagas] : this.filterInput.vagas[0] == null ? null : this.filterInput.vagas,
        cidade: this.$route.query.cidade ? [this.$route.query.cidade] : this.filterInput.cidade[0] == null ? null : this.filterInput.cidade,
        bairro: this.$route.query.bairro ? [this.$route.query.bairro] : this.filterInput.bairro[0] == null ? null : this.filterInput.bairro,
        valor: [(this.$route.query.valormin ? valminInt : this.filterInput.valor[0]), (this.$route.query.valormax ? valmaxInt : this.filterInput.valor[1])],
        metragem: [(this.$route.query.metragemmin ? metminInt : this.filterInput.metragem[0]), (this.$route.query.metragemmax ? metmaxInt : this.filterInput.metragem[1])],
        ordem: this.filterInput.ordem == "" ? null : this.filterInput.ordem,
        tipo: this.$route.query.tipo ? [this.$route.query.tipo] : this.filterInput.tipo[0] == null ? null : this.filterInput.tipo,
        limit: 12,
        empreendimento: this.$route.query.empreendimento ? empreendimento : this.filterInput.empreendimento == [] ? null : this.filterInput.empreendimento,
        restrito: this.$route.query.restrito ? restrito : this.filterInput.restrito == 0 ? null : this.filterInput.restrito,
      };

      api.get<IResponse<IProperties[]>>('/properties', {params})
          .then(res => {

            const data = res.data.data;
            if (data) {
              this.imoveis.error = "";
              this.imoveis.pagination = res.data.pagination;
              return this.imoveis.data = data;
            }
            this.imoveis.error = res.data.message;
          })
          .catch(() => {
            this.imoveis.error = "Não foi possível carregar os imóveis";
          })
          .finally(() => {
            this.imoveis.loading = false;
          })
    },
    getPropertyField(fields: IFieldProperty[], code: number, optional: string = ""): string | null {
      const field = fields.find(field => field.pivot.CodigoCampo == code);
      if (field) {
        return `${this.capitalize(field.Campo)}: ${field.pivot.Valor} ${optional}`;
      }
      return null;
    },
    getValue(data: Array<number>) {
      this.filterInput.valor = data;
    },
    getFootage(data: Array<number>) {
      this.filterInput.metragem = data;
    },
    capitalize(text: string): string {
      try {
        const textLowed = text.toLowerCase();
        return textLowed.split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      } catch (error) {
        return text;
      }
    },
    openShareModal(link: string) {
      this.shareModal = {
        link,
        visible: true
      }
    },
    getPagination(currentPage: number, totalPages: number): number[] {
      const array = [];
      for (let index = currentPage - 4; index <= currentPage + 3 && index <= totalPages; index++) {
        array.push(index);
      }
      const arrayNaturalized = array.filter(value => value > 0);
      if (arrayNaturalized.length > 4 && (currentPage > 1 || currentPage < totalPages)) {
        const index = arrayNaturalized.indexOf(currentPage) - 2 < 0 ? 0 : arrayNaturalized.indexOf(currentPage) - 2;
        return arrayNaturalized.splice(index, 4);
      }
      return arrayNaturalized;
    },
    htmlText(text: string) {
      return `<p> ${text} </p>`;
    },
    arrayUnique(array: Array<number>) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j])
            a.splice(j--, 1);
        }
      }
      return a;
    },
    selectPromotionalImovel(propertyCode: number) {
      var svgCheck = document.getElementById('promotionalImovel-' + propertyCode) as HTMLElement;

      if (this.selectedPromotions.includes(propertyCode)) {
        const index = this.selectedPromotions.indexOf(propertyCode);
        if (index > -1) {
          this.selectedPromotions.splice(index, 1);
          if (svgCheck)
            svgCheck.style.display = 'none';
        }
      } else {
        this.selectedPromotions.push(propertyCode);
        if (svgCheck)
          svgCheck.style.display = 'block';
      }
    },
  }
})
