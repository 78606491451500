
  import { routeFilters } from "@/routes";
  import { defineComponent } from "vue";
  import { LocationQuery, RouteRecordName } from "vue-router";
  import api from '@/config/api';
  import allowedSubdomains from "@/config/allowedSubdomains";

  type VariablesData = {
    creci: string,
    endereco: string,
    fone: string,
    email: string,
    whatsapp: string
  }

  type DataTypes = {
    filters: any;
    corretor: VariablesData;
  }


  export default defineComponent({
    data(): DataTypes {
      return {
        filters: routeFilters,
        corretor:{
          creci: '4576J',
          endereco: 'Rua Monsenhor Celso, 231. Centro, Curitiba - PR',
          fone: '41 3014.1101',
          email: 'contato.galvao@vendasg.com.br',
          whatsapp: '41 3014.1101'
        }
      };
    },
    mounted(){
      this.getRealtor();
    },
    computed: {
      location: () => { return window.location.href; },
      isMobile() {
        return window.innerWidth <= 768;
      }
    },
    methods: {
      isActive(name: RouteRecordName, params?: LocationQuery): string {
        try {
          if(params && this.$router.currentRoute.value.name.toString() == name) {
            return JSON.stringify(this.$router.currentRoute.value.query) == JSON.stringify(params) ? 'active' : null;
          }
          return this.$router.currentRoute.value.name.toString() == name ? 'active' : null;
        } catch (error) {
          return null;
        }
      },
      getRealtor(){

          api
          .get('/agent/getAgents')
          .then(res => {

              const allowedOrigins = allowedSubdomains;
              
              const subdomain = window.location.host.split('.')[0];

              if(allowedOrigins.includes(subdomain) && !subdomain.includes('galvaovendas') && !subdomain.includes('localhost')){
                  const corretor = res.data.data;
                  
                  corretor.forEach((value: any)=> {

                      if(value.corretor_nome.toUpperCase().includes(subdomain.toUpperCase())){
                          this.corretor.creci = value.creci;
                          this.corretor.fone = value.corretor_phone;
                          this.corretor.email = value.corretor_email;
                          this.corretor.whatsapp = value.corretor_phone_whatsapp;
                      }
                  })

              }

          })
          .catch(err => {
              console.log(err);
          })
      }
    }
  });
