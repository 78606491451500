const allowedOrigins = [
    'lourdes',
    'azevedo', 
    'emiliano',
    'celles',
    'silvestre',
    'renata',
    'fernando',
    'jaqueline',
];
export default allowedOrigins;