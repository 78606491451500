
    import { defineComponent } from 'vue';
    import api, { getCookie } from '@/config/api';
    import AdminLayout from '@/screens/layouts/AdminLayout.vue';

    type FormData = {
        email: string;
        senha: string;
    }

    type DataTypes = {
        dados: FormData;
        leadMessage: string;
        errors: string[];
        loading: boolean;
        customErrorMessage: boolean;
    }

    type ApiResponseLoginJson = {
        message: string;
        errors?: string[];
    }

    export default defineComponent({
        components: {
            AdminLayout
        },
        data(): DataTypes {
            return {
                dados: {
                    email: null,
                    senha: null
                },
                leadMessage: "",
                errors: [],
                loading: false,
                customErrorMessage: false
            };
        },
        created() {
            if(getCookie('Authorization') != "") {
                this.$router.replace('painel');
            }
        },
        methods: {
            sendLoginForm(): void {
                this.loading = true;
                this.showErrors({} as ApiResponseLoginJson);

                api
                .post<ApiResponseLoginJson>('auth/login', this.dados)
                .then(res => {
                    this.dados = {} as FormData;
                    this.showErrors(res.data);
                    var value:any = res.data;
                    var token = value.data.token;
                    var date = new Date(value.data.expiration);
                    var dateStr = date.toUTCString();
                    this.setCookie('Authorization',"=Bearer "+token,dateStr);
                    
                    this.$router.push('painel');
                })
                .catch((error) => {
                    const data: ApiResponseLoginJson = error.response.data || {
                        message: "Houve um erro ao processar requisição"
                    };
                    this.showErrors(data);
                    this.customErrorMessage = true;
                })
                .finally(() => this.loading = false);
            },
            showErrors(data: ApiResponseLoginJson): void {
                this.leadMessage = data.message;
                this.errors = data.errors || [];
            },
            setCookie(name:string,value:string,date:string) {
                document.cookie = name + (value || "")  + "; expires=" + date + "; path=/";
            }, 
            delete_cookie(name: string) {
                document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            },
        }
    })
