
import { IPagination, IProperties, IResponse } from '@/config/apiTypes';
import { defineComponent, ref } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';
import MultipleSelect from '@/components/input/MultipleSelect.vue';
import MultipleCheck from '@/components/input/MultipleCheck.vue';
import PropertyCard from '@/components/PropertyCard.vue';
import SearchSelect from '@/components/input/SearchSelect.vue';
import MainLayout from '@/screens/layouts/MainLayout.vue';
import Pagination from '@/components/Pagination.vue';
import ShareModal from '@/components/ShareModal.vue';
import Slider from '@vueform/slider';
import api from '@/config/api';
import '@vueform/slider/themes/default.css';
import FloatingButtons from '@/components/FloatingButtons.vue';

export interface IFilters<T> {
    data: T[];
    loading: boolean;
}

declare interface googleMapsMarkers {
    lat: number; 
    lng: number;
}

interface filtersInputType {
    tipo: Array<string>;
    cidade: Array<string>;
    bairro: Array<string>;
    codigo: Array<number>;
    referencia: string;
    endereco: string;
    dormitorios: Array<number>;
    lancamento: "";
    imovelPronto: "";
    banheiros: Array<number>;
    suites: Array<number>;
    vagas: Array<number>;
    valor: Array<number>;
    metragem: Array<number>;
    ordem: string;
    empreendimento: Array<string>;
    tipoEmpreendimento: Array<string>;
    page: number;
}

declare interface dataType {
    mapsApiKey: string;
    activeCheckboxId: string | null;
    seeMap: {
        status: boolean,
        loading: boolean
    };
    center: googleMapsMarkers;
    markers: { 
        options: {
            position: googleMapsMarkers
        },
        click: any
    }[];
    tipos: IFilters<{ Tipo: string }>;
    bairros: IFilters<{ Bairro: string }>;
    cidades: IFilters<{ Cidade: string }>;
    empreendimentos: IFilters<{ Empreendimento: string }>;
    tipoEmpreendimentos: IFilters<{ TipoEmpreendimento: string }>;
    filterInput: filtersInputType;
    imoveis: {
        data: IProperties[];
        error: string | null;
        loading: boolean;
        pagination: IPagination;
    };
    shareModal: {
        visible: boolean;
        link: string;
    };
    valueMax: {
        valueArea: number ;
        valueImovel: number ;
    }
}

interface filterParamsType {
    [key: string]: string;
}

const filterParams: filterParamsType = {
    codigo: "array",
    referencia: "string",
    endereco: "string",
    dormitorios: "array",
    banheiros: "array", 
    suites: "array", 
    vagas: "array", 
    cidade: "array", 
    bairro: "array",
    valor: "array", 
    metragem: "array",
    lancamento: "string",
    imovelPronto: "string",
    tipo: "array", 
    empreendimento: 'array',
    tipoEmpreendimento: 'array',
    ordem: "string", 
    page: "string"
}

export default defineComponent({
    beforeMount() {
        this.getQueriesToInputs();
        this.getProperties();
        this.getMax();
    },
    components: {
        MainLayout,
        Slider,
        GoogleMap, 
        Marker,
        Pagination,
        MultipleSelect,
        MultipleCheck,
        SearchSelect,
        PropertyCard,
        ShareModal,
        FloatingButtons
    },
    setup() {
        const mapRef = ref(null);
        return { mapRef }
    },
    data(): dataType {
        return {
            mapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
            activeCheckboxId: null,
            seeMap: {
                status: false,
                loading: false
            },
            center: { lat: -25.4364664, lng: -49.266462 },
            markers: [],
            tipos: {
                data: [],
                loading: false
            },
            cidades: {
                data: [],
                loading: false
            },
            bairros: {
                data: [],
                loading: false
            },
            empreendimentos: {
                data: [],
                loading: false
            },
            tipoEmpreendimentos:{
                data: [],
                loading: false
            },
            filterInput: {
                tipo: [],
                cidade: [],
                bairro: [],
                codigo: [],
                referencia: "",
                endereco: "",
                dormitorios: [],
                banheiros: [],
                suites: [],
                vagas: [],
                valor: [],
                lancamento: "",
                imovelPronto: "",
                metragem: [],
                empreendimento: [],
                tipoEmpreendimento: [],
                ordem: "",
                page: 1
            },
            imoveis: {
                data: [],
                error: null,
                loading: false,
                pagination: null
            },
            shareModal: {
                visible: false,
                link: ""
            },
            valueMax:{
                valueArea: null,
                valueImovel: null
            }
        };
    },
    methods: {
        showEndereco(){
            const btnPesquisar = document.getElementById('btn_pesquisar')
            const endereco = document.getElementById('endereco')

            if (window.getComputedStyle(endereco).display == 'none'){
                endereco.style.display = 'block'
                btnPesquisar.style.justifyContent = ''
            }else {
                endereco.style.display = 'none'
                btnPesquisar.style.justifyContent = 'center'
            }
        },
        getMax(){
            api
            .get('auth/max')
            .then(ref => {
                this.valueMax.valueImovel = ref.data.preco;
                this.valueMax.valueArea = ref.data.area;
                
                this.filterInput.valor = [0, this.valueMax.valueImovel];
                this.filterInput.metragem = [0, this.valueMax.valueArea];
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
            });
        },
        formatValue(value: number) {
            return value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });
        },
        formatFootage(value: number) {
            return `M² ${Math.round(value)}`
        },
        getQueriesToInputs() {
            this.filterInput = {
                ...this.filterInput,
                ...this.$route.query
            }
        },
        getValue(data: Array<number>) {
            this.filterInput.valor = data;
        },
        getFootage(data: Array<number>) {
            this.filterInput.metragem = data;
        },
        getPropertyReference(data: string) {
            this.filterInput.referencia = data;
        },
        getPropertyAddress(data: string) {
            this.filterInput.endereco = data;
        },
        showCheckboxes(checkboxDivId: string): void {
            this.activeCheckboxId = this.activeCheckboxId == checkboxDivId ? null : checkboxDivId;
            window.onclick = (e: MouseEvent) => {
                var target = e.target as HTMLElement;
                if(!target.closest(".multiple-select"))
                    this.activeCheckboxId = null;
            };
        },
        getEnterprises(search?: string) {
            if(this.empreendimentos.data.length == 0 || search) {
                this.empreendimentos.loading = true;
                const params = search ? { pesquisa: search } : null
                api.get('/enterprises', { params })
                .then(res => {
                    const data = res.data.data;
                    if(data) {
                        this.empreendimentos.data = data;
                    }
                })
                .finally(() => {
                    this.empreendimentos.loading = false;
                })
            }
        },
        getTypeEnterprises(search?: string) {
             if(this.tipoEmpreendimentos.data.length == 0 || search) {
                this.tipoEmpreendimentos.loading = true;
                const params = search ? { pesquisa: search } : null
                api.get('/typeenterprises', { params })
                .then(res => {
                    console.log(res.data)
                    const data = res.data.data;
                    if(data) {
                        this.tipoEmpreendimentos.data = data;
                    }
                })
                .finally(() => {
                    this.tipoEmpreendimentos.loading = false;
                })
            }
        },
        getTypes() {
                if(this.tipos.data.length == 0) {
                    this.tipos.loading = true;
                    api.get('/types')
                    .then(res => {
                        const data = res.data.data;
                        if(data) {
                            this.tipos.data = data;
                        }
                    })
                    .finally(() => {
                        this.tipos.loading = false;
                    })
                }
            },
        getCities() {
                if(this.cidades.data.length == 0) {
                    this.cidades.loading = true;
                    api.get('/cities/tops')
                    .then(res => {
                        const data = res.data.data;
                        if(data) {
                            this.cidades.data = data;
                        }
                    })
                    .finally(() => {
                        this.cidades.loading = false;
                    })
                }
            },
        getDistricts() {
                if(this.bairros.data.length == 0) {
                    this.bairros.loading = true;
                    api.get('/districtsSite?todos=Sim')
                    .then(res => {
                        const data = res.data.data;
                        if(data) {
                            this.bairros.data = data;
                        }
                    })
                    .finally(() => {
                        this.bairros.loading = false;
                    })
                }
            },
        getFilterParamsFromQueryAndInput(page?: number) {
            try {
                const rawParams = { ...this.$route.query, ...this.filterInput, page };
                
                const paramsKV = Object.entries(rawParams).map(([key, value]) => {
                    if(value != null && value != "" && Object.keys(filterParams).includes(key)) {
                        const newValue = (filterParams[key] == "array" && typeof value != "object") ? [value] : value;
                        return [key, newValue];
                    }
                }).filter(Boolean);
                const params = Object.fromEntries(paramsKV.values());

                this.$router.replace({ query: params });
                this.filterInput = { ...this.filterInput, ...params };
                console.log(params);
                return params;
            } catch (error) {
                console.error("Houve um erro ao carregar filtros: ", error);
                return {};
            }
        },
        getProperties(page?: number) {
            this.imoveis.loading = true;

            const params = this.getFilterParamsFromQueryAndInput(page);
            api.get<IResponse<IProperties[]>>('/properties', { params })
            .then(res => {
                const data = res.data.data;
                if(data) {
                    this.imoveis.error = "";
                    this.imoveis.pagination = res.data.pagination;
                    return this.imoveis.data = data;
                }
                this.imoveis.error = res.data.message;
            })
            .catch(() => {
                this.imoveis.error = "Não foi possível carregar os imóveis";
            })
            .finally(() => {
                this.imoveis.loading = false;
            })
        },
        changePage(page: number) {
            const query = { ...this.$route.query, page };
            this.$router.replace({ query });

            this.getProperties(page);
        },
        openShareModal(link: string) {
            this.shareModal = {
                link,
                visible: true
            }
        },
        fetchMarks() {
        this.seeMap.loading = true;
          const params = this.getFilterParamsFromQueryAndInput();
          api.get('marks', { params })
              .then(response => {
                const data = response.data.data;
                console.log(data)
                const markers = data.map((mark: any) => {
                  return {
                    options: {
                      position: {
                        lat: mark.Latitude,
                        lng: mark.Longitude
                      }
                    },
                    click: () => {
                      let routeData = this.$router.resolve({
                        name: 'imovel',
                        params: {
                          id: mark.CodigoImovel,
                          slug: mark.URLAmigavel
                        }
                      });
                      window.open(routeData.href, '_blank');
                    }
                  }
                })
                this.markers = markers;
              })
              .catch(() => {
                alert("Não foi possível carregar as coordenadas dos imóveis.");
              })
              .finally(() => {
                this.seeMap.loading = false;
              })
        }
    },
    watch: {
        "seeMap.status"(value) {
            if(value && this.markers.length == 0) {
              this.fetchMarks()
            }
        },
    },
    
})
