
import { IFilters } from "@/screens/BuscarImoveis.vue";
import { defineComponent, PropType } from "vue";

interface DataTypes {
  isOpened: boolean;
  search: string;
  value: string[];
}

export default defineComponent({
    props: {
        name: {
            type: String,
            default: "Teste"
        },
        id: {
            type: String,
            default: "",
            required: true
        },
        data: {
            type: Object as PropType<IFilters<Object[]>>,
            default: () => { 
                return { 
                    data: [{Empreendimento: ""}],
                    loading: false 
                    }
                },
            required: true
        },
        modelValue: {
            type: Array as PropType<string[]>,
            required: true
        },
      exclusiveSearch: {
        type: Boolean,
        required: false
      },
        getDataFunction: {
            type: Function as PropType<() => void>,
            default: () => () => {}
        }
    },
    emits: ['update:modelValue'],
    data(): DataTypes {
        return {
            isOpened: false,
            value: [],
            search: '',
        }
    },
    methods: {
        showCheckboxes(): void {
            this.isOpened = !this.isOpened;
                window.onclick = (e: MouseEvent) => {
                    var target = e.target as HTMLElement;
                    if(!target.closest(".multiple-select"))
                        this.isOpened = false;
                };
        },
      resetCheckboxes(): void {
        // eslint-disable-next-line no-undef
            const checkboxes = document.querySelectorAll('input:checked') as NodeListOf<HTMLInputElement>;
            [...checkboxes].forEach(item => item.click());
      }
    },
    watch: {
        value(val) {
          if (this.exclusiveSearch) {
            val = val.slice(-1);
            this.resetCheckboxes();
            // if (typeof val[0] != 'undefined') {
            //   let value = document.querySelector(`#option-${val[0].replace(/\s|\W/g, '')}`) as HTMLInputElement;
            //   value.checked = true;
            // }
          }
            this.$emit('update:modelValue', val);
        }
    }
});
