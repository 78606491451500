
import { defineComponent } from "@vue/runtime-core";

interface DataTypes {
    values: any[],
    selected: string[]
}

export default defineComponent({
    props: {
        name: String,
        modelValue: Array
    },
    data(): DataTypes {
        return {
            values: [1, 2, 3, 4],
            selected: []
        }
    },
    watch: {
        selected(val) {
            this.$emit('update:modelValue', val);
        }
    }
})
