
import {defineComponent} from 'vue'
import api, {getCookie} from '@/config/api';

type ApiResponseContactJson = {
  message: string;
  errors?: string[];
}

declare interface DataType {
  leadMessage: string;
  errors: string[];
  loading: boolean;
  cookie: string;
}

export default defineComponent({
  mounted() {
    this.isLogged();
  },
  beforeMount() {
    if (api.defaults.headers.common['Authorization'] != "") {
      this.cookie = getCookie('Authorization')
      if (this.cookie)
        api.defaults.headers.common['Authorization'] = this.cookie;
      else
        this.$router.push('login')
    }
  },
  data(): DataType {
    return {
      leadMessage: "",
      errors: [],
      loading: true,
      cookie: ""
    }
  },
  methods: {
    isLogged() {
      api
          .get<ApiResponseContactJson>('auth/painel')
          .then(res => {
            this.showErrors(res.data);
          })
          .catch((error) => {
            console.error(error)
            this.$router.push('login');
          })
          .finally(() => {
            this.loading = false
          });
    },
    logout() {
      this.delete_cookie('Authorization');
      this.$router.push('login');
    },
    delete_cookie(name: string) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    showErrors(data: ApiResponseContactJson): void {
      this.leadMessage = data.message;
      this.errors = data.errors || [];
    },
  },
})
