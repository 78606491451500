import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0829df39"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multiple-check" }
const _hoisted_2 = ["value", "onInput", "name", "id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `inputMultipleCheck${index}${_ctx.name}`
      }, [
        _withDirectives(_createElementVNode("input", {
          value: value,
          onInput: ($event: any) => (_ctx.$emit('input', $event.target.value)),
          type: "checkbox",
          hidden: "",
          name: _ctx.name,
          id: `${_ctx.name}Checkbox${index}`,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
        }, null, 40, _hoisted_2), [
          [_vModelCheckbox, _ctx.selected]
        ]),
        _createElementVNode("label", {
          for: `${_ctx.name}Checkbox${index}`
        }, _toDisplayString(index == _ctx.values.length - 1 ? value + "+" : value), 9, _hoisted_3)
      ]))
    }), 128))
  ]))
}