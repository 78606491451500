
import { defineComponent } from 'vue';
import api, { getCookie } from '@/config/api';
import AdminLayout from '@/screens/layouts/AdminLayout.vue';


type ApiResponseContactJson = {
    message: string;
    errors?: string[];
}

declare interface dataType {
    leadMessage: string;
    errors: string[];
    loading: boolean;
    districts: string[];
    districtsFiltered: Array<string>;
    father_id_list: Array<number>;
    filterInput:{
        bairro: string;
    },
    modal:{
        title: string;
        message: string;
        button: string;
        father_id: number;
        form:{
            id_pai_original: number;
            id_pai_editado: number;
            bairro_original: string;
            bairro_editado: string;
            action: string;
        }
    }
}

export default defineComponent({
    components:{
        AdminLayout    
    },
    beforeMount(){
        if(api.defaults.headers.common['Authorization'] != ""){
            const cookie = getCookie('Authorization')
            if(cookie)
                api.defaults.headers.common['Authorization'] = cookie;
            else
                this.$router.push('login')
        }
    },
    mounted(){
        this.getDistricts();
        this.isLogged();
    },
    data(): dataType {
        return {
            leadMessage: null,
            errors: [],
            loading: true,
            districts: [],
            districtsFiltered: [],
            father_id_list: [],
            filterInput:{
                bairro: null
            },
            modal:{
                title: null,
                message: null,
                button: null,
                father_id: null,
                form:{
                    id_pai_original: null,
                    id_pai_editado: null,
                    bairro_original: null,
                    bairro_editado: null,
                    action: null,
                }
            }
        }
    },
    methods:{
        //Obtém todos os bairros da tabela 'bairros'
        getDistricts(){
            
            api
            .get('districts')
            .then(res => {
                this.showErrors(res.data);
                this.districts = res.data.tableBairros;

                // console.log(res.data.tableBairros)
                
                this.districts.forEach((value: any) => {
                    this.districtsFiltered.push(value.bairro);
                    this.father_id_list.push(value.id);
                });
                
            })
            .catch(err => {
                console.log('Erro na requisição -> ',err);
            })
            .finally( () => {
                this.loading = false;
            })
        },
        showErrors(data: ApiResponseContactJson): void {
            this.leadMessage = data.message;
            this.errors = data.errors || [];
        },
        isLogged(){
            api
            .get<ApiResponseContactJson>('auth/painel')
            .then(res => {
                this.showErrors(res.data);
            })
            .catch((error) => {
                console.error(error)
                this.$router.push('login');
            })
            .finally(() => {
                this.loading = false
            });
        },
        search(){
            this.districtsFiltered = [];
            this.districts.forEach((value: any) => {
                if(value.bairro.toUpperCase().includes(this.filterInput.bairro.toUpperCase()) && value.bairro!=""){
                    this.districtsFiltered.push(value.bairro);
                }
            });
        },
        change(bairro: string, action: string, id_pai: number){
            this.modal.form.action = action;
            this.modal.form.bairro_original = bairro;
            this.modal.form.bairro_editado = bairro;
            this.modal.form.id_pai_original = id_pai;
            this.modal.form.id_pai_editado = id_pai;

            if(this.modal.form.action === 'edit'){
                this.modal.title = 'Edição de Bairro';
                this.modal.message = '';
                this.modal.button = 'Alterar';
            } else if(this.modal.form.action === 'remove'){
                this.modal.title = 'Remoção de Bairro';
                this.modal.message = 'Tem certeza que deseja remover este Bairro? Todos os imóveis que estão vinculados à ele não possuirão mais um Bairro!';
                this.modal.button = 'Remover';
            }
        },
        resetForm(){
            this.modal.form.bairro_editado = this.modal.form.bairro_original;
            this.modal.form.id_pai_editado = this.modal.form.id_pai_original;
        },
        sendChanges(){

            const msgErro = document.getElementById('msgErro') as HTMLElement;
            if(msgErro){
                msgErro.classList.remove("showError");
                msgErro.classList.add("hideError");
            }

            if(!this.father_id_list.includes(this.modal.form.id_pai_editado)){
                if(msgErro){
                    msgErro.classList.remove("hideError");
                    msgErro.classList.add("showError");
                }
            } else{
                api
                .post<ApiResponseContactJson>('districts', this.modal.form)
                .then(res => {
                    console.log(res);
                    //this.modal.form.bairro_original = this.modal.form.bairro_editado;
                    //this.modal.form.id_pai_original = this.modal.form.id_pai_editado;
                })
                .catch(err => {
                    err;            
                })
                .finally(() => {
                    document.location.reload(true);
                })
            }

        },
    }
});
