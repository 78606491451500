
import { defineComponent } from "vue";
import FloatingButtons from '@/components/FloatingButtons.vue';
import CookiesWarning from '@/components/CookiesWarning.vue';

export default defineComponent({
  components: {
    FloatingButtons, CookiesWarning
  }
});
