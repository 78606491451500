<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog">
        <form id="div-inputs" action="#" method="POST" @submit.prevent="handleSubmit">
          <img :src="getUrl(property.capa)"/>

          <label class="refs mt-2" for="">Referencia</label>
          <p class="refs">{{ property.Referencia }}</p>
          <label class="refs mb-2" for="">Valor Promocional</label>
          <input v-model="Valor" class="form-control input-modal" type="text"/>

          <button class="border-0 btn btn-info button mt-4" type="submit">Atualizar</button>
        </form>
        <button class="close row-md-6 mt-4" type="button" @click="$emit('close')">
          x
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/config/api";
import {formatCashNumber, getUrl} from '@/utils/functions';

export default {
  setup() {
    return {formatCashNumber};
  },
  props: {
    property: {
      type: Object
    },
  },
  data() {
    return {
      oldValor: null,
      Valor: null,
      codigoImovel: null,
    };
  },
  methods: {
    getUrl,
    handleSubmit() {
      this.updateValue();
    },
    updateValue() {
      if (this.Valor != this.property.ValorImovel) {
        let oldValue = this.property.ValorImovel
        api.post('auth/update', {
          'value': this.Valor,
          'oldValue': oldValue,
          'codigoImovel': this.codigoImovel
        }).then(res => {
          console.log(res.data)
        }).finally(() => {
          this.$emit('save');
        })
      }
    },
  },
  watch: {
    property: function () {
      this.Valor = this.property.ValorImovel;
      this.codigoImovel = this.property.CodigoImovel;
      if (this.property.ValorAntigo) {
        console.log(this.property.ValorAntigo)
      } else {
        this.oldValor = this.Valor;
        console.log(this.oldValor);
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.modal {
  background: #eeeeee;
  border-radius: 10px;
  display: flex;
  width: 60%;
  height: 690px;
  position: fixed;
  top: 15%;
  left: 28%;
  padding: 30px;
}

#div-inputs {
  width: 100%;
}

label {
  font-weight: bold;
  padding-top: 5px;
}

#div-inputs .input-modal:focus {
  box-shadow: none;
  border: 3px solid #81344b !important;
}

.close {
  background: #eeeeee;
  width: 70px;
  height: 50px;
  color: #eeeeee;
  border: none;
  border-radius: 3px;
  right: -5px;
  top: -25px;
  position: absolute;
}

.close:hover {
  color: #81344b;
}

.button {
  background-color: #8a3a51;
}

.button:hover {
  background-color: #702940;
  color: #c4c2c2;
}

img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  margin-top: 3%;
}

.refs {
  font-size: 16px;
  color: #eeeeee;
  font-weight: bold;
  align-items: center;
}

</style>
