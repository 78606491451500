
import { IFilters } from "@/screens/BuscarImoveis.vue";
import { defineComponent, PropType } from "vue";

interface DataTypes {
    isOpened: boolean;
    value: string[];
}

export default defineComponent({
    props: {
        name: {
            type: String,
            default: "Teste"
        },
        data: {
            type: Object as PropType<IFilters<Object[]>>,
            default: () => { return { data: [{ Cidade: "Joao" }], loading: false }}
        },
        modelValue: {
            type: Array as PropType<string[]>,
            required: true
        },
        getDataFunction: {
            type: Function as PropType<() => void>,
            default: () => () => {}
        }
    },
    emits: ['update:modelValue'],
    data(): DataTypes {
        return {
            isOpened: false,
            value: []
        }
    },
    methods: {
        showCheckboxes(): void {
            this.isOpened = !this.isOpened;
            window.onclick = (e: MouseEvent) => {
                var target = e.target as HTMLElement;
                if(!target.closest(".multiple-select"))
                    this.isOpened = false;
            };
        },
    },
    watch: {
        value(val) {
            this.$emit('update:modelValue', val);
        }
    }
});
