
import {defineComponent} from 'vue';
// eslint-disable
import api from '@/config/api';
import AdminLayout from '@/screens/layouts/AdminLayout.vue';
import SearchSelect from '@/components/input/SearchSelect.vue';

type ApiResponseContactJson = {
  message: string;
  errors?: string[];
}

export default defineComponent({
  name: "BuscaExclusiva",
  components: {
    AdminLayout,
    SearchSelect
  },
  data() {
    return {
      showSuccessDeleteModal: false,
      showSuccessCreate: false,
      showDeleteConfirmation: false,
      selectedId: null,
      filter: {
        url: '',
        name: '',
        logo: null,
        filter_name: ''
      },
      empreendimentos: {
        data: [],
        loading: false
      },
      searches: {
        data: [],
        loading: false
      },
      deleting: false,
      loading: false,
      message: '',
      errors: null
    }
  },
  computed: {
    url_api() {
      return process.env.VUE_APP_API_URL;
    },
    client_url() {
      return location.origin;
    }
  },
  mounted() {
    this.getExclusiveSearch();
  },
  methods: {
    handleSubmit() {
      this.showErrors({} as ApiResponseContactJson);
      const form = document.querySelector('#buscaExclusiva') as HTMLFormElement;

      if (!form) {
        return this.showErrors({message: "Houve um erro ao enviar formulário"});
      }

      this.loading = true;
      const formData = new FormData(form);
      formData.append('name', this.filter.name);
      console.log(form, formData)
      api.post('/busca-exclusiva/criar', formData,
          {
            headers: {
              'Content-Type': "multipart/form-data"
            }
          })
          .then((response) => {
            this.showErrors(response.data);
            this.showSuccessCreate = true;
            form.reset();
          })
          .catch((error) => {
            const data: ApiResponseContactJson = error.response.data || {
              message: "Houve um erro ao processar requisição"
            };
            this.showErrors(data);
          })
          .finally(() => {
            this.loading = false;
            this.getExclusiveSearch();
            setTimeout(() => {
              this.showSuccessCreate = false
            }, 2000);
          });
    },
    showErrors(data: ApiResponseContactJson): void {
      this.message = data.message;
      this.errors = data.errors || [];
    },
    getEnterprises(search?: string) {
      if(this.empreendimentos.data.length == 0 || search) {
        this.empreendimentos.loading = true;

        const params = search ? { pesquisa: search } : null
        api.get('/typeenterprises', { params })
            .then(res => {
              const data = res.data.data;
              if(data) {
                this.empreendimentos.data = data;
              }
            })
            .finally(() => {
              this.empreendimentos.loading = false;
            })
      }
    },
    getExclusiveSearch() {
      this.searches.loading = true;
      api.get('busca-exclusiva')
      .then(response => {
        this.searches.data = response.data.data
      }).catch(error => {
        const data: ApiResponseContactJson = error.response.data || {
          message: "Houve um erro ao processar requisição"
        };
        this.showErrors(data);
      }).finally(() => {
        this.searches.loading = false;
      })
    },
    confirmDeletion(id: string) {
      this.selectedId = id;
      this.showDeleteConfirmation = true;
    },
    deleteExclusiveSearch() {
      this.deleting = true;
      api.delete('busca-exclusiva/' + this.selectedId)
      .then(response => {
        console.log(response)
        this.showSuccessDeleteModal = true;
      })
      .catch(error => {
        const data: ApiResponseContactJson = error.response.data || {
          message: "Houve um erro ao processar requisição"
        };
        this.showErrors(data);
      })
      .finally(() => {
        this.deleting = false;
        this.selectedId = null;
        this.showDeleteConfirmation = false;
        this.getExclusiveSearch();
        setTimeout(() => {
          this.showSuccessDeleteModal = false
        }, 2000);
      })
    },
    cancelDelete() {
      this.showDeleteConfirmation = false;
      this.selectedId = null;
    }
  }
});
